import React, { Component } from 'react'
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux'
import { NavLink } from 'react-router-dom';
import { ADMIN_URL, ITEMS_PER_PAGE, ITEMS_PAGE_RANGE_DISPLAYED, TRANSLATABLE_FIELDS } from '../../../constants.js'

import { listProducts, removeProduct, editProduct, addImage, editVariant, setProductFilter, clearProductFilter, syncProductsWithWS } from '../../../actions/productsActions';
import { listCategories } from '../../../actions/categoriesActions';
import { listSubcategories } from '../../../actions/subcategoriesAction';
import Pagination from "react-js-pagination";
import Filter from './../common/Filter';
import ListEachProduct from './ListEachProduct';
import Auth from './../../../utils/Auth'
import Utils from './../../../utils/Utils';
import MessagesContainer from './../../messages/MessagesContainer';
import LangDropdown from '../../common/LangDropdown.js';


// import 'bootstrap/dist/css/bootstrap.css';


class ListProductsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currLang: this.props.currLang,
            // search_name: '',
            // search_isLiquid: '',
            // search_active: '',
            // search_category: '',
            productsPriority: {},
            productsWsCodes: {},
            productVariantEditMode: false,
            productNames: {},
            productDescs: {},
            warehouseSystemId: 0,

        };


    }

    componentWillMount() {
        this.props.listProducts(Auth.getUserPlace());
        // this.props.listCategories();
        // this.props.listSubcategories();
    }

    componentWillReceiveProps(nextProps) {
        // if (nextProps.search_category) {
        //     this.props.setProductFilter('search_category', nextProps.search_category);
        //     // this.setState({
        //     //     search_category: nextProps.search_category
        //     // })
        // }
        let _self = this;

        if (nextProps.prods) {

            let productsPriority = {};
            Object.values(nextProps.prods).map(prod => {
                productsPriority[prod.id] = prod.priority;
            })

            let productsWsCodes = {};
            Object.values(nextProps.prods).map(prod => {
                productsWsCodes[prod.id] = prod.ws_code;
            })

            let productNames = {};
            Object.values(nextProps.prods).map(prod => {
                productNames[prod.id] = prod.translations[_self.state.currLang] && prod.translations[_self.state.currLang].name
                    ? prod.translations[_self.state.currLang].name : prod.name;
            })

            let productDescs = {};
            Object.values(nextProps.prods).map(prod => {
                productDescs[prod.id] = prod.description;
            })

            this.setState({ productsPriority: productsPriority, productsWsCodes: productsWsCodes, productNames: productNames, productDescs: productDescs })
        }
    }

    toggleProductVariantEditMode = (param) => {
        this.setState({ productVariantEditMode: param || false });
    }

    deleteProduct = (e, id) => {
        e.preventDefault();
        this.props.removeProduct(id);
    }

    onChangeCheckbox = (e, id) => {
        const post = {
            id: id,
            // isLiquid: this.state.isLiquid,
            active: e.target.checked,
        }
        let formData = new FormData();
        this.props.editProduct(post, null, formData, this.props.languages[this.state.currLang].id);
    }

    onChangeCheckboxIsLiquid = (e, id) => {
        const post = {
            id: id,
            isLiquid: e.target.checked,
        }
        this.props.editProduct(post, null, null, this.props.languages[this.state.currLang].id);
    }

    handlePageChange = (page) => {
        // this.props.dispatch(push('/admin/products?page=' + page))

        let routeQuery = `?page=${page}`;
        Object.keys(this.props.query).map(key => {
            if (key != 'page') {
                routeQuery += `&${key}=${this.props.query[key]}`
            }
        })
        this.props.dispatch(push('/admin/products' + routeQuery))
    }

    onChange = (e) => {
        console.log("SETT ", e.target.name, e.target.value)
        this.props.setProductFilter([e.target.name], e.target.value);
        this.handlePageChange(1);

        // if (e.target.name == "search_category") {
        //     this.props.setProductFilter('search_category', e.target.value);
        //     // let routeQuery = `?search_category=${e.target.value}&page=1`;
        //     // Object.keys(this.props.query).map(key => {
        //     //     if(key != 'search_category') {
        //     //         routeQuery += `&${key}=${this.props.query[key]}`
        //     //     }
        //     // })
        //     // this.props.dispatch(push('/admin/products' + routeQuery))
        // } else {
        //     this.setState({ [e.target.name]: e.target.value });
        // }
    }

    updatePriority = (e, id) => {
        e.preventDefault();

        const post = {
            id: id,
            priority: this.state.productsPriority[id]
        }
        this.props.editProduct(post);
        // this.props.editProduct(post, null, formData, this.props.languages[this.state.currLang].id);
    }


    updateProductVariantPrice = (e, id, price, size) => {
        e.preventDefault();

        const post = {
            id: id,
            price: price,
            size: size
        }

        this.props.editVariant(post, this.props.history, this.props.languages[this.state.currLang].id);
    }

    updateProductVariantWSCode = (e, id, ws_code) => {
        e.preventDefault();

        const post = {
            id: id,
            ws_code: ws_code,
        }

        this.props.editVariant(post, this.props.history, this.props.languages[this.state.currLang].id);
    }

    onPriorityChange = (e, productId) => {
        let priority = e.target.value;
        let productsPriority = this.state.productsPriority;

        productsPriority[productId] = priority;
        this.setState({ productsPriority: productsPriority })
    }

    updateWsCode = (e, id) => {
        e.preventDefault();
        const post = {
            id: id,
            ws_code: this.state.productsWsCodes[id]
        }
        this.props.editProduct(post);
    }

    onProdNameChange = (e, productId) => {
        let name = e.target.value;
        let productNames = this.state.productNames;

        productNames[productId] = name;
        this.setState({ productNames: productNames })

    }

    onProdDescChange = (e, productId) => {
        let name = e.target.value;
        let productDescs = this.state.productDescs;

        productDescs[productId] = name;
        this.setState({ productDescs: productDescs })

    }

    updateProdName = (e, id) => {
        e.preventDefault();
        const post = {
            id: id,
            name: e.target.value
        }

        let formData = new FormData();
        this.props.editProduct(post, this.props.history, formData, this.props.languages[this.state.currLang].id);
    }

    updateProd = (e, id, key) => {
        e.preventDefault();
        const post = {
            id: id,
            [key]: e.target.value
        }

        let formData = new FormData();
        this.props.editProduct(post, this.props.history, formData, this.props.languages[this.state.currLang].id);
    }

    onWsCodeChange = (e, productId) => {
        let wsCode = e.target.value;
        let productsWsCodes = this.state.productsWsCodes;

        productsWsCodes[productId] = wsCode;
        this.setState({ productsWsCodes: productsWsCodes })
    }

    syncProducts() {
        if (window.confirm(this.props.translations.products.sync_products_confirmation) == true) {
            this.props.syncProductsWithWS()
        }
    }

    currLangOnChange = (e) => {
        let newLang = e.target.value;

        let newState = {
            currLang: newLang
        };

        let productNames = {};
        Object.values(this.props.prods).map(prod => {
            productNames[prod.id] = prod.translations[newLang] && prod.translations[newLang].name
                ? prod.translations[newLang].name : prod.name;
        })

        newState.productNames = productNames;

        // TRANSLATABLE_FIELDS.PRODUCT .map(field => {
        // 	newState[field] = this.props.prod.translations[newLang] && this.props.prod.translations[newLang][field] ? this.props.prod.translations[newLang][field] : "";
        // })

        // let newSizeValue = this.state.variants[this.state.editedVariantId]
        //     && this.state.variants[this.state.editedVariantId].translations[newLang]
        //     && this.state.variants[this.state.editedVariantId].translations[newLang].size
        //     || '';

        // newState['variantSize'] = newSizeValue;
        this.setState(newState)
    }

    render() {
        // console.log('this.props.categoriesHierarchy',this.props.categoriesHierarchy)
        let prodItems = this.props.prods;
        // const { subcats } = this.props;
        if (this.props.search_name != ''
            || this.props.search_isLiquid != ''
            || this.props.search_active != ''
            || this.props.search_category != ''
            || this.props.search_ws_code != ''
        ) {
            prodItems = Object.values(prodItems).filter(i => {
                return (
                    (this.props.search_name != '' ? (i.name).toLocaleLowerCase().includes((this.props.search_name).toLocaleLowerCase()) : true)
                    && (this.props.search_category != '' ? i.cat_hierarchies.filter(cat => cat.id == (parseInt(this.props.search_category))).length > 0 : true)
                    && (this.props.search_active != '' ? i.active == Boolean(parseInt(this.props.search_active) - 1) : true)
                    && (this.props.search_isLiquid != '' ? i.isLiquid == Boolean(parseInt(this.props.search_isLiquid) - 1) : true)
                    && (this.props.search_ws_code != '' ? (this.props.search_ws_code == 1 ? i.ws_code && i.ws_code.length > 0 : (i.ws_code == null || i.ws_code.length == 0)) : true)
                )
            })
        }

        // const per_page = 5;
        const items_count = Object.keys(prodItems).length;
        const current_page = this.props.page || 0;
        const start_offset = (current_page - 1) * ITEMS_PER_PAGE;
        let start_count = 0;

        // let variantsss = [];

        return (
            <main className="main">

                {/* <!-- Breadcrumb--> */}
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink></li>
                    {/* <li className="breadcrumb-item">Меню</li> */}
                    <li className="breadcrumb-item active">{this.props.translations.products.title}</li>

                    {/* <!-- Breadcrumb Menu--> */}
                    <li className="breadcrumb-menu d-md-down-none" >
                        <div className="btn-group" role="group" aria-label="Button group">

                            {Utils.isWHSActive() ?
                                <button className='btn btn-default' onClick={() => this.syncProducts()}>{this.props.translations.products.sync_products}</button>
                                :
                                null
                            }

                            {/* DO NOT REMOVE */}
                            {Auth.hasAccess('SUPER_ADMIN') ?
                                <NavLink to={this.props.currPlace && this.props.currPlace.warehouseSystemId && this.props.currPlace.warehouseSystemId == 4 ? '#' : ADMIN_URL + `bulk-add-products-v2/`} className="btn" >
                                    <span style={{
                                        opacity:
                                            this.props.currPlace && this.props.currPlace.warehouseSystemId && this.props.currPlace.warehouseSystemId == 4 ? '0.5' : 1
                                    }}><i className="fas fa-plus" style={{ color: "white" }}>&nbsp;</i></span>
                                </NavLink>
                                :
                                null
                            }
                            {/* DO NOT REMOVE */}

                            <NavLink to={this.props.currPlace && this.props.currPlace.warehouseSystemId && this.props.currPlace.warehouseSystemId == 4 ? '#' : ADMIN_URL + `products/add`} className="btn" >
                                <span style={{
                                    opacity:
                                        this.props.currPlace && this.props.currPlace.warehouseSystemId && this.props.currPlace.warehouseSystemId == 4 ? '0.5' : 1
                                }}><i className="fas fa-plus"></i> &nbsp; {this.props.translations.products.add}</span>
                            </NavLink>
                            <NavLink to={this.props.currPlace && this.props.currPlace.warehouseSystemId && this.props.currPlace.warehouseSystemId == 4 ? '#' : ADMIN_URL + `products/add-bulk`} className="btn" >
                                <span style={{
                                    opacity:
                                        this.props.currPlace && this.props.currPlace.warehouseSystemId && this.props.currPlace.warehouseSystemId == 4 ? '0.5' : 1
                                }}><i className="fas fa-plus"></i> &nbsp; {this.props.translations.products.add_bulk}</span>
                            </NavLink>


                            {/* <div className="btn-group" role="group" aria-label="Button group">
                                <button
                                    className="btn btn-warning btn-ladda ladda-button"
                                    style={{ padding: 5, color: 'white' }}
                                    onClick={() => this.setState({ visibleFilter: !this.state.visibleFilter })}
                                >
                                    {this.props.translations.common.filter}
                                </button>
                            </div> */}
                        </div>
                    </li>
                </ol>

                <div className="container-fluid">
                    <div className="animated fadeIn">
                        <div className="row">

                            <Filter
                                translations={this.props.translations}
                                onChange={this.onChange}
                                categoriesHierarchy={this.props.categoriesHierarchy}
                                productFilter={this.props.productFilter}
                                clearFilter={this.props.clearProductFilter}
                                prodItems={prodItems}
                            />

                            {/* <!-- /.col--> */}
                            <div className="col-lg-12">
                                <div className="card">

                                    <div className="card-body">

                                        <MessagesContainer />

                                        {localStorage.getItem('tabl_admin_hide_infotext_listproductseasyedit') ?
                                            null :
                                            <div className="alert alert-info alert-dismissible fade show" role="alert">Вече може да редактирате всяка част от текста по-долу само с едно натискане върху него.
                                                <button type="button" class="close" aria-label="Close" style={{ float: 'right' }} onClick={() => { localStorage.setItem('tabl_admin_hide_infotext_listproductseasyedit', 1); window.location.reload(); }}>
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                        }

                                        <LangDropdown
                                            isDisabled={""}
                                            currLangOnChange={this.currLangOnChange}
                                            currLang={this.state.currLang}
                                            languages={this.props.languages}
                                        />

                                        <table className="table table-responsive-sm table-striped">
                                            <thead>
                                                <tr>
                                                    <th>{this.props.translations.common.name}</th>
                                                    {Utils.getSettingsValue(this.props.settings, 'show_edit_description_in_list_products_page') == '1' ?
                                                        < th > {this.props.translations.common.description}</th>
                                                        :
                                                        null
                                                    }
                                                    <th>{this.props.translations.common.category}</th>
                                                    {/* {this.props.noCategoriesView != 4 ? 
                                                        <th>{this.props.translations.common.subcategory}</th>
                                                    :
                                                    null} */}
                                                    <th>{this.props.translations.common.image}</th>
                                                    <th>{this.props.translations.common.size}</th>
                                                    <th>{this.props.translations.common.price}</th>
                                                    {(Auth.hasAccess("SUPER_ADMIN") || Auth.hasAccess('CLIENT_ADMIN') || Auth.hasAccess('PLACE_ADMIN')) && this.props.currPlace && this.props.currPlace.warehouseSystemId ?
                                                        <th>
                                                            <span title={this.props.translations.product_variants.ws_code_text}>
                                                                {this.props.translations.products.ws_code}
                                                                <span data-toggle="tooltip" data-placement="bottom"> 🛈</span>
                                                            </span>
                                                            {/* {this.props.translations.common.ws_code} */}
                                                        </th>
                                                        :
                                                        null
                                                    }
                                                    {/* <th>{this.props.translations.common.is_drink}</th> */}
                                                    <th>{this.props.translations.common.active_text}</th>
                                                    <th>
                                                        <span title={this.props.translations.common.priority_description}>
                                                            {this.props.translations.common.priority}
                                                            <span data-toggle="tooltip" data-placement="bottom"> 🛈</span>
                                                        </span>
                                                    </th>
                                                    {(Auth.hasAccess("SUPER_ADMIN") || Auth.hasAccess('CLIENT_ADMIN') || Auth.hasAccess('PLACE_ADMIN')) && this.props.currPlace && this.props.currPlace.warehouseSystemId ?
                                                        <th>
                                                            <span title={this.props.translations.products.ws_code_text}>
                                                                {this.props.translations.products.ws_code}
                                                                <span data-toggle="tooltip" data-placement="bottom"> 🛈</span>
                                                            </span>
                                                        </th>
                                                        :
                                                        null
                                                    }
                                                    <th>{this.props.translations.common.action}</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {Object.values(prodItems).sort(Utils.compareForPriority()).map((prod, index) => {
                                                    // if (prod.variants && Object.keys(prod.variants).length > 0) {
                                                    //     Object.values(prod.variants).map(a => variantsss.push(a.id))
                                                    //     // variantsss.push(Object.keys(prod.variants)[0])
                                                    // }
                                                    if (index >= start_offset && start_count < ITEMS_PER_PAGE) {
                                                        start_count++;
                                                        return <ListEachProduct
                                                            key={prod.id}
                                                            product={prod}
                                                            priority={this.state.productsPriority && this.state.productsPriority[prod.id] || ''}
                                                            onPriorityChange={this.onPriorityChange}
                                                            updatePriority={this.updatePriority}
                                                            wsCode={this.state.productsWsCodes && this.state.productsWsCodes[prod.id] || ""}
                                                            onWsCodeChange={this.onWsCodeChange}
                                                            updateWsCode={this.updateWsCode}
                                                            deleteProduct={this.deleteProduct}
                                                            editProduct={this.props.editProduct}
                                                            translations={this.props.translations}
                                                            currLang={this.state.currLang}
                                                            categoriesHierarchy={this.props.categoriesHierarchy}
                                                            onChangeCheckbox={(e) => this.onChangeCheckbox(e, prod.id)}
                                                            onChangeCheckboxIsLiquid={e => this.onChangeCheckboxIsLiquid(e, prod.id)}
                                                            currPlace={this.props.currPlace}
                                                            productVariantEditMode={this.state.productVariantEditMode}
                                                            toggleProductVariantEditMode={this.toggleProductVariantEditMode}
                                                            updateProductVariantPrice={this.updateProductVariantPrice}
                                                            updateProdName={this.updateProdName}
                                                            updateProd={this.updateProd}
                                                            onProdNameChange={this.onProdNameChange}
                                                            onProdDescChange={this.onProdDescChange}
                                                            productName={this.state.productNames[prod.id]}
                                                            productDesc={this.state.productDescs[prod.id]}
                                                            updateProductVariantWSCode={this.updateProductVariantWSCode}
                                                            addImage={this.props.addImage}
                                                            history={this.props.history}
                                                            // noCategoriesView={this.props.noCategoriesView}
                                                            settings={this.props.settings}
                                                        />
                                                    }
                                                })}
                                            </tbody>
                                        </table>
                                        {/* {variantsss ? console.log('variantsss: ', variantsss) : void (0)} */}

                                        <Pagination
                                            className="pagination"
                                            itemClass="page-item"
                                            activePage={current_page}
                                            activeClass="active"
                                            linkClass="page-link"
                                            prevPageText="<"
                                            nextPageText=">"
                                            firstPageText="<<"
                                            lastPageText=">>"
                                            itemsCountPerPage={ITEMS_PER_PAGE}
                                            totalItemsCount={items_count}
                                            pageRangeDisplayed={ITEMS_PAGE_RANGE_DISPLAYED}
                                            onChange={this.handlePageChange}
                                        />

                                        {/* <ul className="pagination">
                                            <li className="page-item">
                                                <a className="page-link" href="#">Prev</a>
                                            </li>
                                            <li className="page-item active">
                                                <a className="page-link" href="#">1</a>
                                            </li>
                                            <li className="page-item">
                                                <a className="page-link" href="#">2</a>
                                            </li>
                                            <li className="page-item">
                                                <a className="page-link" href="#">3</a>
                                            </li>
                                            <li className="page-item">
                                                <a className="page-link" href="#">4</a>
                                            </li>
                                            <li className="page-item">
                                                <a className="page-link" href="#">Next</a>
                                            </li>
                                        </ul> */}
                                    </div>
                                </div>
                            </div>
                            {/* <!-- /.col--> */}

                        </div>
                    </div>
                </div>

            </main >
        )
    }
}


const mapStateToProps = state => {
    let productsPriority = {};
    if (state.products.products) {
        Object.values(state.products.products).map(prod => {
            productsPriority[prod.id] = prod.priority;
        })
    }

    const productFilter = state.products.filter && Object.keys(state.products.filter).length > 0 ? JSON.parse(state.products.filter) : '';

    let currPlace = state.places.places.find(p => p.id == Auth.getUserPlace());


    // let currPlaceId = Auth.getUserPlace();

    // let currPlace = state.places.places.find(s => s.id == currPlaceId);

    // if (!currPlace) currPlace = {
    // 	id: '',
    // 	name: '',
    // 	active: 1,
    // 	warehouseSystemId: '',
    // 	warehouse_system_credentials: "{}"
    // };

    return (
        {
            settings: state.settings.settings,
            prods: state.products.products,
            productsPriority: productsPriority,
            page: Number(state.router.location.query.page) || 1,
            // search_category: Number(state.router.location.query.search_category) || '',
            query: state.router.location.query,
            translations: state.lang,
            // subcats: state.subcategories.subcategories,
            categoriesHierarchy: state.categoriesHierarchy.categories || [],
            languages: state.languages.languages || {},
            currLang: state.settings.settings['default_lang'] && state.settings.settings['default_lang'].value || "bg",
            productFilter: productFilter,
            search_category: productFilter && Object.keys(productFilter).length > 0 && productFilter.search_category ? productFilter.search_category : '',
            search_name: productFilter && Object.keys(productFilter).length > 0 && productFilter.search_name ? productFilter.search_name : '',
            search_isLiquid: productFilter && Object.keys(productFilter).length > 0 && productFilter.search_isLiquid ? productFilter.search_isLiquid : '',
            search_active: productFilter && Object.keys(productFilter).length > 0 && productFilter.search_active ? productFilter.search_active : '',
            search_ws_code: productFilter && Object.keys(productFilter).length > 0 && productFilter.search_ws_code ? productFilter.search_ws_code : '',
            currPlace: currPlace || null
            // noCategoriesView: state.settings.settings && state.settings.settings['no_categories_view'] && state.settings.settings['no_categories_view'].value ? state.settings.settings['no_categories_view'].value : 4,

        }
    )
}


const mapDispatchToProps = (dispatch, props) => {
    return {
        dispatch,
        ...bindActionCreators({
            listProducts, removeProduct, editProduct, addImage, editVariant, listCategories, listSubcategories, setProductFilter,
            clearProductFilter, syncProductsWithWS
        }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListProductsPage);
