export const esTranslations = {
    sidemenu: {
        alergens: "Alérgenos",
        dashboard: "Inicio",
        menu: "Menú",
        categories: "Categorías",
        categoriesHierarchy: "Categorías",
        subcategories: "Subcategorías",
        products: "Productos",
        administration: "Admin",
        tables: "Mesas",
        orders: "Pedidos",
        all_orders: "Todos los pedidos",
        orders_from_spot: "Del local",
        orders_delivery: "Con entrega",
        orders_preorder: "Para recoger",
        orders_room: "Desde la habitación",
        deleted_orders: "Todos /eliminados/",
        charts: "Informes",
        reservations: "Reservas",
        locations: "Establecimientos",
        rooms: "Salas",
        reservation_tables: "Mesas reservadas",
        schedule: "Horario",
        settings: "Configuraciones",
        users: "Usuarios",
        system: "Sistema",
        super_admin: "Súper admin",
        current_place: "Ubicación actual",
        clients: "Clientes",
        place: "Ubicación",
        places: "Ubicaciones",
        ingredients: "Ingredientes",
        statistics: "Estadísticas",
        statistics_users: "Est. Usuarios",
        statistics_orders: "Est. Pedidos",
        delivery_regions: "Regiones de entrega",
        feedback: "Valoraciones",
        feedback_questions: "Valoraciones - preguntas",
        feedback_answers: "Valoraciones - respuestas",
        work_users: "Usuarios de trabajo",
        registered_users: "Registrados",
        invoices: "Facturas",
        auto_invoices: "Facturas automáticas",
        proforms: "Proformas",
        bookings: "Reservas",
        promocodes: "Códigos promocionales",
        general_promotions: "Promociones generales",
        place_images: "Galería"
    },
    common: {
        notification: "Notificaciones",
        notifications: "Notificaciones",
        create: "Crear",
        show_cat_only_for_spot_orders_text: "Mostrar solo para pedidos en el local",
        hide_cat_only_for_spot_orders_text: "Ocultar solo para pedidos en el local",
        is_paying: "Pagando",
        next: "Siguiente",
        count_promo_codes: "Cantidad de códigos promocionales",
        ordertype: "Tipo de pedido",
        promocode: "Código promocional",
        discount_value: "Descuento - valor",
        discount_type: "Descuento - tipo",
        valid_from: "Válido desde",
        valid_to: "Válido hasta",
        from: "Desde",
        to: "Hasta",
        discount: "Descuento",
        valid: "Validez",
        used: "Usado",
        not_used: "No usado",
        months: {
            1: "Enero",
            2: "Febrero",
            3: "Marzo",
            4: "Abril",
            5: "Mayo",
            6: "Junio",
            7: "Julio",
            8: "Agosto",
            9: "Septiembre",
            10: "Octubre",
            11: "Noviembre",
            12: "Diciembre"
        },
        completed: "Completado",
        prefix: "Prefijo",
        place_in_url: "Añadir el nombre de la ubicación en el enlace al código QR",
        number_start: "Número desde",
        number_end: "Número hasta",
        add_bulk: "Añadir en masa",
        no_internet: "Falta de conexión a internet",
        day_1: "Lunes",
        day_2: "Martes",
        day_3: "Miércoles",
        day_4: "Jueves",
        day_5: "Viernes",
        day_6: "Sábado",
        day_7: "Domingo",
        max_count_addable_ingredients: "Cantidad máxima",
        min_count_addable_ingredients: "Cantidad mínima",
        hide_variants: "Ocultar variantes del producto en la página de vista de todos los productos",
        active_in: "Activo en:",
        warning: "Advertencia",
        info: "Información",
        color: "Color",
        back: "Atrás",
        included: "incl.",
        link: "Enlace",
        box: "Caja",
        boxes: "Cajas",
        done: "Hecho",
        background: "Fondo",
        color: "Color",
        title: "Título",
        paid: "Pagado",
        waitings: "En espera",
        order: "Pedido",
        alergens: "Alérgenos",
        id: "Número",
        dashboard: "Inicio",
        category_name: "Nombre de la categoría",
        product_name: "Nombre del producto",
        name: "Nombre",
        short_name: "Nombre corto",
        icon: "Ícono",
        status: "Estado",
        action: "Acción",
        category: "Categoría",
        client: "Cliente",
        subcategory: "Subcategoría",
        size: "Tamaño",
        price: "Precio",
        is_drink: "Bebida",
        number: "Número",
        order_number: "Número de pedido",
        order_type: "Tipo de pedido",
        date: "Fecha",
        turnover: "Facturación",
        code: "Código",
        active_text: "Activo",
        active: "Activa",
        inactive: "Inactiva",
        description: "Descripción",
        image: "Imagen",
        main_image: "Imagen principal",
        background_image: "Imagen de fondo",
        yes: "Sí",
        no: "No",
        add: "Añadir",
        cancel: "Cancelar",
        save: "Guardar",
        delete: "Eliminar",
        close: "Cerrar",
        edit: "Editar",
        price_of_variant: "Precio de la variante",
        table: "Mesa",
        room: "Habitación",
        bill_wanted: "Cuenta solicitada",
        bill: "Cuenta",
        currency: "€",
        download: "Descargar",
        value: "Valor",
        images: "Imágenes",
        rows: "Filas",
        type: "Tipo",
        restaurant_order: "Pedido de restaurante",
        delivery: "Entrega",
        preorder: "Pedido anticipado",
        delivery_and_preorder: "Entrega y pedido anticipado",
        restaurant_and_delivery: "Pedido de restaurante y entrega",
        restaurant_and_preorder: "Pedido de restaurante y pedido anticipado",
        others: "Otros",
        role: "Rol",
        additional_price: "Precio adicional",
        slug: "Slug",
        cash: "En efectivo",
        card: "Con tarjeta",
        on_room_bill: "En la cuenta de la habitación",
        level: "Nivel en la jerarquía",
        parent_category: "Categoría principal",
        filter: "Filtro",
        place: "Ubicación",
        activeDaysOfWeek: {
            1: "Lun",
            2: "Mar",
            3: "Mié",
            4: "Jue",
            5: "Vie",
            6: "Sáb",
            7: "Dom"
        },
        types: {
            default: "Por defecto",
            image: "Con imagen",
            info: "Informativa",
            link: "Enlace",
            booking: "Reserva"
        },
        open_call_waiterCat: "Abrir 'Llamar al camarero'",
        activeStartTime: "Hora de inicio activa",
        activeEndTime: "Hora de finalización activa",
        is_promo_product: "Producto promocional",
        is_promo_product_info: "Aparece como ventana emergente para una categoría específica. Una vez activado, se puede establecer desde la página de edición de la categoría en la pestaña 'Productos promocionales para la categoría'",
        is_image_cat: "Categoría de imágenes",
        priority: "Prioridad",
        priority_description: "Cuanto menor sea el número, más adelante se mostrará (1 - primero, 2 - y así sucesivamente)",
        start_date: "Fecha de inicio",
        end_date: "Fecha de finalización",
        add_row: "Añadir fila",
        remove_row: "Eliminar fila",
        delivery_price: "Precio de entrega",
        price_over: "Por encima de (precio)",
        price_over_info: "Precio del pedido, por encima del cual se aplicará el precio",
        rotation_start_date: "Fecha de inicio de la rotación",
        rotation_start_date_info: "La categoría se mostrará en el menú de forma rotativa cada {Días de rotación} desde la fecha de inicio",
        rotation_days_count: "Días de rotación",
        rotation_days_count_info: "El número de días durante los cuales se mostrará la categoría",
        order_allowed: "Pedido permitido",
        order_allowed_info: "Permite/prohíbe pedidos para la categoría en cuestión. (Si un producto en la categoría respectiva está en varias categorías, se podrá pedir si no se prohíben los pedidos en la otra categoría)",
        hide_for_ordering: "Ocultar producto",
        hide_for_ordering_info: "Oculta el producto del menú",
        fast_order_info: "Gestiona la visibilidad del banner desde Configuraciones > Banners",
        image_scale: "Acercamiento de imagen",
        discount_percent: "Porcentaje de descuento",
        discount_money: "Descuento en dinero",
        discount_percent_info: "Establece un porcentaje de descuento para todos los productos en esta categoría",
        deleted: "Eliminado",
        isLiquid: "Bebida",
        label: "Etiqueta",
        room_number: "Número de habitación",
        remove_current_worktime: "Marcar como no laborable",
        save_current_worktime_for_whole_week: "Establecer este horario para toda la semana",
        question: "Pregunta",
        answer: "Respuesta",
        approved: "Aprobado",
        not_approved: "No aprobado",
        user: "Usuario",
        orders: "Pedidos",
        total_amount: "Total",
        booking_duration: "Duración de la reserva",
        table_or_room: "Mesa/Habitación",
        value_type: "Tipo",
        active_start_time: "Activo desde",
        active_end_time: "Activo hasta",
        on_order_number: "Número de pedido en el que se activará el descuento",
        time_until_order_ready: "Tiempo hasta que esté listo",
        ws_code: "Código en WS",
        with_ws_code: "Con código",
        without_ws_code: "Sin código",
        productPrintGroupId: "Grupo de impresión (ATENCIÓN)",
        choose_product_print_group: "Por favor, elija el grupo de impresión (ATENCIÓN)",
        show_only_for_spot_browse: "Mostrar solo para visualización en el local (se oculta para pedidos de entrega, recogida, etc.)",
        hide_only_for_spot_browse: "Ocultar solo para visualización en el local (se muestra para pedidos de entrega, recogida, etc.)",
        show_cat_only_for_delivery_orders_text: "Mostrar solo para entrega",
        hide_cat_only_for_delivery_orders_text: "Ocultar solo para entrega",
        external_system_region_id: "ID de región en sistema externo",
        city: "Ciudad",
        hour: "Hora"
    },
    versions: {
        features_about_version: "Nuevas funcionalidades de la versión:",
        version: "Versión"
    },
    alergens: {
        title: "Alérgenos",
        add: "Añadir alérgeno",
        edit: "Editar alérgenos",
        delete: {
            title: "Eliminar alérgeno",
            confirm: "¿Estás seguro de que deseas eliminar el alérgeno?"
        }
    },
    places: {
        title: "Ubicaciones",
        add: "Añadir ubicación",
        edit: "Editar ubicación",
        ordertypes: "Tipos de pedido",
        edit: "Editar",
        phone: "Número de teléfono para notificaciones SMS en caso de pedidos no vistos *",
        delete: {
            title: "Eliminar ubicación",
            confirm: "¿Estás seguro de que deseas eliminar la ubicación?"
        },
        copy_menu_from: "Copiar menú de:",
        init_settings_for_all_places: "Inicializar configuraciones para todas las ubicaciones",
        functionality_modules: "Módulos funcionales",
        sms_logs: "SMS por meses",
        additional_info: "Información adicional (acuerdos)",
        company_email: "Correo electrónico",
        lat: "Lat",
        lng: "Lng",
        visible_on_map: "Visible en el mapa",
        city: "Ciudad",
        tags: "Etiquetas (para filtrar en la página del mapa)"
    },
    categories: {
        title: "Categorías",
        description: "Descripción",
        add: "Añadir categoría",
        edit: "Editar categoría",
        delete: {
            title: "Eliminar categoría",
            confirm: "¿Estás seguro de que deseas eliminar la categoría?"
        },
        tabs: {
            edit: "Editar",
            promo_products_for_category: "Productos promocionales para la categoría",
            images_category: "Categoría de imágenes",
            booking: "Reserva",
            video: "Vídeo"
        },
        places_for_category: "Ubicaciones donde se mostrará la categoría",
        promo_title: "Título de la ventana emergente de promoción",
        booking_start_work_time: "Hora de inicio de trabajo para la reserva (ej. 10:00)",
        booking_end_work_time: "Hora de finalización de trabajo para la reserva (ej. 18:00)"
    },
    subcategories: {
        title: "Subcategorías",
        add: "Añadir subcategoría",
        edit: "Editar subcategoría",
        delete: {
            title: "Eliminar subcategoría",
            confirm: "¿Estás seguro de que deseas eliminar la subcategoría?"
        }
    },
    products: {
        replace_image: "Cambiar imagen",
        upload_image: "Subir imagen",
        fast_order: "Visible en banner de Pedido rápido",
        title: "Productos",
        title_single: "Producto",
        ingredients: "Ingredientes",
        variants: "Variantes",
        add: "Añadir producto",
        add_bulk: "Añadir muchos productos",
        edit: "Guardar producto",
        add_variant: "Añadir variante",
        save_variant: "Guardar variante",
        delete_image: "Eliminar imagen",
        delete: {
            title: "Eliminar producto",
            confirm: "¿Estás seguro de que deseas eliminar el producto?"
        },
        related_products: "Productos relacionados",
        related_products_info: "Cuando se añade el producto al carrito, los 'Productos relacionados' aparecen como ventana emergente para upselling (El cliente añade una hamburguesa, aparece una ventana emergente sugiriendo patatas fritas)",
        bulk_update_products_field: "Cambiar muchos productos",
        bulk_update_products_field_info: "El campo seleccionado de todos los productos filtrados será cambiado con el valor establecido. ¡Usar con precaución!",
        ws_code: "Código en WS",
        ws_code_text: "Código del producto en el sistema de almacén",
        sync_products: "Sincronizar con el almacén",
        sync_products_confirmation: "Esto sincronizará los nombres y precios de los productos en el menú con los del sistema de almacén. ¿Estás seguro?",
        show_only_for_spot_browse: "Mostrar solo para visualización en el local",
        hide_only_for_spot_browse: "Ocultar solo para visualización en el local",
        comment_placeholder: "Comentario - placeholder"
    },
    product_variants: {
        delete: {
            title: "Eliminar variante",
            confirm: "¿Estás seguro de que deseas eliminar la variante?"
        },
        ws_code_text: "Código de la variante en el sistema de almacén. Tiene prioridad sobre el código del producto en WS"
    },
    ingredients: {
        addable: "Ingredientes añadibles",
        removable: "Ingredientes removibles",
        required: "Ingredientes obligatorios",
        title: "Ingredientes",
        add: "Añadir",
        edit: "Editar ingrediente"
    },
    orders: {
        activete_sound_dialog_title: "Esta pantalla contiene notificaciones de sonido y vibración (para teléfonos móviles)",
        activete_sound_dialog_description: "",
        select_tables: "Seleccionar mesas",
        no_data: "No hay datos en la sección seleccionada",
        hide_deleted: "Ocultar eliminados",
        show_deleted: "Ver eliminados",
        hide_completed: "Ocultar completados",
        show_completed: "Ver completados",
        client_name: "Nombre del cliente",
        phone: "Teléfono",
        client_phone: "Teléfono del cliente",
        client_address: "Dirección del cliente",
        products: "Productos",
        order_date: "Hora del pedido",
        order_date_short: "Pedido a las",
        update_date: "Última modificación",
        update_date_short: "Modificado a las",
        type: "Tipo de pedido",
        user_needs: "Necesidades del cliente",
        payment_type: "Tipo de pago",
        serve_time: "Llevar a las: ",
        client_pickup_time: "Recoger a las: ",
        client_delivery_time: "Entrega a las: ",
        room_number: "Número de habitación: ",
        select_table_regions: "Seleccionar regiones de mesas",
        user_needs_list: {
            "clean_table": "Limpiar la mesa",
            "ice": "Hielo",
            "napkins": "Servilletas",
            "new_cutlery": "Nuevos cubiertos",
            "change_ashtray": "Cambiar cenicero",
            "other": "Otro"
        },
        types: {
            delivery: "CON ENTREGA",
            preorder: "PARA RECOGER",
            spot: "Del local",
            room: "Desde la habitación",
            tablet_mode: "Modo tablet",
            served_on_spot: "Servido en el local"
        },
        statuses: {
            ordered: "Pedido",
            cooked_spot: "Preparado",
            cooked_room: "Preparado",
            served_spot: "Servido",
            cooked_delivery: "Preparado",
            on_the_way_delivery: "En camino",
            cooked_preorder: "Preparado",
            cooked_room: "Preparado",
            paid: "Pagado",
            rejected: "Rechazar pedido"
        },
        all: "Todos",
        new: "Nuevos",
        ordered: "Nuevos",
        cooked: "Preparados",
        served: "Servidos",
        for_delivering: "Para entrega",
        delivering: "Entregando",
        waiting_for_pickup: "Esperando al cliente",
        paid: "Pagados",
        future: "Futuros",
        delivered: "Entregados",
        delete: {
            title: "Eliminar pedido",
            confirm: "¿Estás seguro de que deseas eliminar el pedido #?"
        },
        delivery_date_short: "Entrega a las",
        pickup_date_short: "Recogida a las",
        connected_to_printer: "CONECTADO A LA IMPRESORA",
        connect_to_printer: "CONECTAR A LA IMPRESORA",
        connect_to_mini_comp: "CONEXIÓN A UNA MINI COMPUTADORA",
        connected_to_mini_comp: "CONECTADO A UNA MINI COMPUTADORA",
        push_notifications: "Notificaciones push",
        delete_all: "Borrar todo por hoy",
        warehouse_errors_modal_title: "Sistema de almacén - error",
        paid_online: "PAGADO EN LÍNEA",
        paid_with_epay: "Pagado con Epay",
    },
    messages: {
        errors: {
            invalid_code: "Código inválido",
            invalid_name: "Nombre inválido",
            invalid_link: "Enlace inválido",
            invalid_category: "Categoría inválida",
            invalid_subcategory: "Subcategoría inválida",
            invalid_size: "Tamaño inválido",
            invalid_price: "Precio inválido",
            invalid_table_num: "Número de mesa inválido",
            invalid_table_num_start: "Número de mesa inicial inválido",
            invalid_table_num_end: "Número de mesa final inválido",
            invalid_email: "Correo electrónico inválido",
            invalid_role: "Rol inválido",
            you_must_select_place: "Debes seleccionar una ubicación",
            no_data_to_insert: "Datos vacíos",
            invalid_price_over: "Precio inválido por encima de",
            invalid_crop_image: "Debes subir y recortar la imagen",
            invalid_slug: "Slug inválido",
            invalid_ordertype: "Tipo de pedido inválido",
            invalid_place: "Ubicación inválida",
            invalid_credentials: "Credenciales de inicio de sesión inválidas",
            reached_max_rows_save_to_proceed: "Has alcanzado el límite de filas. Por favor, guarda los actuales para continuar.",
            sound_not_found: "No se encontró la notificación sonora. No serás notificado para nuevos pedidos. Contacta con el soporte técnico o recarga la página.",
            invalid_start_work_time: "Hora de inicio de trabajo inválida",
            invalid_end_work_time: "Hora de finalización de trabajo inválida",
            invalid_min_people_count: "Cantidad mínima de personas inválida",
            invalid_max_people_count: "Cantidad máxima de personas inválida",
            invalid_tables: "Debes seleccionar mesas",
            min_people_must_be_less_than_max_people: "La cantidad mínima de personas debe ser menor que la cantidad máxima de personas",
            invalid_city: "Debes seleccionar una ciudad",
            invalid_phone: "Introduce un teléfono para enviar un SMS en caso de pedido no visto. 359..... o introduce '359' SOLO si la ubicación es de prueba.",
            radius_end_must_be_bigger_than_radius_start: "El final del radio debe ser mayor que el inicio",
            radius_end_required: "El final del radio es obligatorio",
            radius_start_required: "El inicio del radio es obligatorio"
        }
    },
    tables: {
        title: "Mesas",
        add: "Añadir mesa",
        edit: "Editar mesa",
        delete: {
            title: "Eliminar mesa",
            confirm: "¿Estás seguro de que deseas eliminar la mesa?"
        },
        x: "Coordenadas X",
        y: "Coordenadas Y",
        w: "Tamaño W",
        h: "Tamaño H",
        generate_qr: "Generar nuevo código QR",
        is_for_reservation: "Activa para reserva",
        reservation_min_people: "Cantidad mín. de personas para reserva",
        reservation_max_people: "Cantidad máx. de personas para reserva",
        restaurant_hall: "Sala",
        ordertype: "Tipo de pedido",
        table_region: "Región",
        is_for_app: "Para la aplicación de menú - mesa que se usa al iniciar sesión en la app de menú",
        is_kiosk_mode: "Para modo quiosco - mesa para pedidos desde quiosco"
    },
    promocodes: {
        title: "Código promocional",
        add: "Añadir código promocional",
        edit: "Editar código promocional"
    },
    settings: {
        menu: "Menú",
        kitchen: "Sistema de cocina",
        admin: "Admin",
        general: "Configuraciones generales",
        background_image: "Imagen de fondo",
        logo: "Logo",
        default_lang: {
            default: "Idioma predeterminado del menú",
            bg: "Búlgaro",
            en: "Inglés",
            ro: "Rumano",
            es: "Española"
        },
        first_init_lang: {
            default: "Traducción inicial del menú al cargar la página - Google Translate",
            without_initial_translate: "Sin traducción inicial al cargar la página",
            bg: "Búlgaro",
            en: "Inglés",
            ro: "Rumano"
        },
        translations_type: {
            default: "Tipo de traducción en el menú",
            visible_description: "- Con Google Translate, la traducción se realiza automáticamente. <br/><br/>- Con la traducción estándar, para reflejar las traducciones diferentes en el menú al cambiar el idioma, es necesario traducir manualmente todas las categorías, productos..",
            1: "Google Translate",
            2: "Traducción estándar"
        },
        change_language_position: {
            default: "Posición del botón de cambio de idioma en el menú",
            visible_description: "1",
            header: "Parte superior",
            footer: "Parte inferior"
        },
        default_currency_suffix: {
            default: "Moneda",
            visible_description: "123"
        },
        view_product_page: {
            default: "Página de vista del producto",
            0: "No",
            1: "Sí - sin icono de añadir producto",
            2: "Sí - con icono de añadir producto"
        },
        place_address: {
            default: "Dirección de la ubicación",
            visible_description: "Se utiliza también para la navegación al crear pedidos del tipo: 'Recoger en local'"
        },
        call_btn_instead_of_search: {
            default: "Número de teléfono",
            visible_description: "Si el campo está vacío, se muestra el botón de búsqueda"
        },
        second_call_btn: {
            default: "Segundo número de teléfono (No es obligatorio)",
            visible_description: "Si el campo está vacío, solo se muestra el primer número de teléfono o el botón de búsqueda"
        },
        show_variant_additional_price: {
            default: "Permitir establecer Precio de caja para la variante /Para entrega y recogida/",
            0: "No",
            1: "Sí"
        },
        meta_title_place_name: {
            default: "Nombre de la ubicación",
            visible_description: "meta title: 'Nombre de la ubicación - Menú digital de TabL'"
        },
        meta_description: {
            default: "Descripción de la ubicación /2-3 frases/",
            visible_description: "meta description"
        },
        about_page_description: {
            default: "Página sobre la ubicación: Descripción (no es obligatorio)"
        },
        about_page_working_time: {
            default: "Página sobre la ubicación: Horario de trabajo",
            visible_description: ""
        },
        about_page_delivery_prices: {
            default: "Página sobre la ubicación: Entrega/Precios",
            visible_description: ""
        },
        about_page_payment_types: {
            default: "Página sobre la ubicación: Tipos de pago",
            visible_description: ""
        },
        about_page_title: {
            default: "Nombre corto de la ubicación (no es obligatorio)",
            visible_description: "Se utiliza si el nombre de la ubicación es muy largo y se visualiza en el menú (el botón en la parte superior izquierda)"
        },
        about_restaurant_page_title_in_left: {
            default: "Página del restaurante - Mover el nombre del restaurante sobre el logo (Se usa si la descripción del restaurante es más larga)",
            0: "No",
            1: "Sí"
        },
        order_button_on_product_variant: {
            default: "Mostrar el botón 'ordenar' y 'comentario' en las variantes del producto.",
            0: "No (solo la variante)",
            1: "Sí - Mostrar la variante, el botón añadir y el botón comentario/abrir (El botón comentario/abrir solo es visible si hay opción de pedido)",
            2: "Sí - Mostrar la variante y el botón añadir"
        },
        show_liquid_on_non_liquid_view_product_page: {
            default: "Generar productos aleatorios en la sección 'OTRAS SUGERENCIAS' de la página de vista del producto",
            0: "Sí",
            1: "No"
        },
        menu_background_image: {
            default: "Imagen de fondo para el menú /No es visible en la página de vista de categorías si son de tipo Imagen/"
        },
        logo: {
            default: "Tu logo"
        },
        tabl_logo: {
            default: "Logo de TabL"
        },
        subcategories_design: {
            default: "Diseño de subcategorías",
            1: "Imágenes",
            2: "Filas",
            3: "En dos columnas con imágenes"
        },
        split_header_category_subcategory_on_two_rows: {
            default: "Dividir las categorías en 2 filas si hay espacio entre las palabras",
            0: "No",
            1: "Sí"
        },
        order_email_visible: {
            default: "Mostrar el campo de correo electrónico al realizar un pedido",
            visible_description: "No se aplica a pedidos de restaurante - solo se escanea allí",
            0: "No",
            1: "Sí"
        },
        promocode_visible: {
            default: "Mostrar el campo de código promocional al realizar un pedido",
            visible_description: "No se aplica a pedidos de restaurante - solo se escanea allí",
            0: "No",
            1: "Sí"
        },
        disable_overtime_orders: {
            default: "Aceptar pedidos SOLO en horario laboral",
            0: "No",
            1: "Sí"
        },
        hide_products_for_ordering_with_token: {
            default: "Activar la posibilidad de ocultar productos para pedidos /Es necesario marcar cada producto como tal/",
            0: "No",
            1: "Sí"
        },
        ask_for_bill_hidden: {
            default: "Ocultar la opción de solicitar cuenta después de un Pedido en el local",
            0: "No",
            1: "Sí"
        },
        user_needs_position: {
            default: "Posición del botón 'Llamar al camarero'",
            header: "Parte superior - menú",
            footer: "Parte inferior"
        },
        hide_order_delivery_pickup_date: {
            default: "Ocultar la opción de elegir hora en pedidos con Entrega/Recogida",
            0: "No",
            1: "Sí"
        },
        hide_order_room_date: {
            default: "Ocultar la opción de elegir hora en pedidos desde la habitación",
            0: "No",
            1: "Sí"
        },
        minimal_order_price: {
            default: "Establecer un valor mínimo para el pedido"
        },
        validate_phone_number_regex: {
            default: "Regex para validar el número de teléfono"
        },
        close_order_modal_after_order: {
            default: "Cerrar la ventana de pedidos después de un pedido",
            0: "No",
            1: "Sí"
        },
        working_hours: {
            default: "Horario de trabajo del establecimiento"
        },
        second_working_hours: {
            default: "Horario de trabajo después del descanso (si hay descanso en el medio del día)"
        },
        min_price_free_delivery: {
            default: "Precio mínimo del pedido por encima del cual la entrega es gratuita"
        },
        delivery_price: {
            default: "Precio de la entrega si el pedido es inferior al mínimo para entrega gratuita. /Se calcula en el precio/"
        },
        order_active_statuses: {
            default: "Gestionar los estados visibles de los diferentes tipos de pedidos",
            visible_description: "Pon 0 en los estados que no quieras mostrar y 1 en los que quieras que sean visibles"
        },
        required_delivery_pickup_times: {
            default: "Establecer si el campo 'Fecha y hora' es obligatorio al realizar un pedido",
            0: "Por defecto se selecciona la hora 'ahora'",
            1: "Debe seleccionarse una hora obligatoriamente"
        },
        sms_phone_after_order: {
            default: "Número de teléfono para enviar SMS en caso de nuevo pedido de tipo Entrega/Recogida/Desde la habitación",
            visible_description: "El número de teléfono debe comenzar con el código, ej.: 359..."
        },
        sms_phone_after_spot_order: {
            default: "Número de teléfono para enviar SMS en caso de nuevo pedido de tipo Desde el local",
            visible_description: "El número de teléfono debe comenzar con el código, ej.: 359..."
        },
        new_order_email_address: {
            default: "Correo electrónico para recibir correos en caso de nuevo pedido de tipo Entrega/Recogida"
        },
        new_room_order_email_address: {
            default: "Correo electrónico para recibir correos en caso de nuevo pedido de tipo Desde la habitación"
        },
        new_spot_order_email_address: {
            default: "Correo electrónico para recibir correos en caso de nuevo pedido de tipo Desde el local"
        },
        show_delivery_price_not_included_text: {
            default: "Mostrar el texto en el carrito: 'El precio de la entrega no está incluido'.",
            0: "No",
            1: "Sí"
        },
        show_cutlery_option_on_making_delivery_order: {
            default: "Mostrar la opción de Cubiertos al realizar un pedido de tipo Entrega",
            0: "No",
            1: "Sí"
        },
        show_cutlery_option_on_making_preorder_order: {
            default: "Mostrar la opción de Cubiertos al realizar un pedido de tipo Recogida",
            0: "No",
            1: "Sí"
        },
        show_cutlery_option_on_making_room_order: {
            default: "Mostrar la opción de Cubiertos al realizar un pedido desde la Habitación",
            0: "Oculta",
            1: "Visible"
        },
        visible_about_page_delivery_prices_text_in_cart: {
            default: "Mostrar el texto sobre el precio de entrega en el carrito",
            visible_description: "Si el precio de la entrega depende del peso del paquete, por ejemplo, marque 'Sí' y describa los precios de la entrega haciendo clic en el enlace al lado o abajo",
            0: "No",
            1: "Sí"
        },
        clickable_product_variants: {
            default: "Añadir el icono 'carrito' en la variante del producto",
            0: "No",
            1: "Sí"
        },
        variant_modal_with_comment: {
            default: "Siempre abrir un nuevo modal (con opción de comentario) al intentar añadir un producto al carrito",
            0: "No",
            1: "Sí"
        },
        hide_product_images_without_img: {
            default: "Ocultar la imagen por defecto del producto que no tiene imagen",
            0: "No",
            1: "Sí"
        },
        preorder_percent_discount: {
            default: "Descuento para pedido de tipo Recogida - en porcentaje /No se calcula en el precio/"
        },
        ordertype_prices: {
            delivery: "Entrega - precios"
        },
        ordertype_discounts: {
            delivery: "Entrega - descuentos",
            pickup: "Recogida - descuentos",
            spot: "Pedido en el restaurante - descuentos"
        },
        menu_header_background: {
            default: "Fondo de la barra superior (Header)"
        },
        menu_header_icons_color: {
            default: "Color del texto de los botones de la barra superior (Header)"
        },
        menu_call_button_background: {
            default: "Fondo del botón de llamada"
        },
        menu_call_button_color: {
            default: "Color del texto del botón de llamada"
        },
        menu_top_left_dialpad_button_background: {
            default: "Fondo de los botones en la barra superior izquierda (menú)"
        },
        menu_top_left_dialpad_button_color: {
            default: "Color del texto de los botones en la barra superior izquierda (menú)"
        },
        menu_product_addtocart_button_background: {
            default: "Fondo del botón de añadir al carrito - Página de lista de productos"
        },
        menu_product_addtocart_button_color: {
            default: "Color del texto del botón de añadir al carrito - Página de lista de productos"
        },
        menu_product_viewpage_addtocart_button_background: {
            default: "Fondo del botón de añadir al carrito - Página de vista de producto"
        },
        menu_product_viewpage_addtocart_button_color: {
            default: "Color del texto del botón de añadir al carrito - Página de vista de producto"
        },
        menu_dialogs_background: {
            default: "Fondo de Modales/Diálogos"
        },
        menu_dialogs_colors: {
            default: "Color del texto de Modales/Diálogos"
        },
        menu_underline_colors: {
            default: "Fondo de la barra de subrayado (Debajo de las categorías y debajo de los botones en 'menú' (arriba a la izquierda)"
        },
        bigger_logo: {
            default: "Logo más grande /El color no es relevante por el momento/"
        },
        product_theme: {
            default: "Tema de los productos",
            0: "Tema 1 - Bar /Moderno/",
            1: "Tema 2 - Bar /Sencillo/",
            2: "Tema 3 - Restaurante /Moderno/",
            3: "Tema 4 - Restaurante /Sencillo/",
            4: "Tema 5 - Hotel /Moderno/",
            5: "Tema 7 - Hotel /Sencillo/",
            6: "Tema 8 - Hotel /NUEVO/"
        },
        admin_notify_on_new_order: {
            default: "Notificación sonora en caso de nuevo pedido",
            1: "Marcar el pedido como leído al hacer clic en el botón 'Nuevos'",
            2: "Marcar el pedido como leído al cambiar su estado"
        },
        allow_auto_print_orders: {
            default: "Imprimir automáticamente los nuevos pedidos",
            0: "No",
            1: "Sí"
        },
        SMS_CLIENT_API_KEY: {
            default: "API KEY PARA CLICKATELL",
            0: "q8ppKOM_Sy-gydxpYqKXvw== /Ga - 55, Bulg - 54/",
            1: "OXCQWa2cRrGHi0JKi_-SWA== /Re - 19, Fo - 21, Flo - 47/",
            2: "BhOHhBm2RkG7FPmfeaHcrA== /SYSTEM PHONES/"
        },
        delivery_radiuses: "Entrega - radios",
        delivery_shapes: "Entrega - mapas"
    },
    "settings_categories": {
        "Languages": "Idioma y localización",
        "Products": "Productos",
        "Images": "Imágenes",
        "CategoriesAndSubcategories": "Categorías y subcategorías",
        "Orders": "Pedidos",
        "General": "General",
        "OrdersDelivery": "Pedidos - Entrega",
        "OrdersPreorder": "Pedidos - Recogida en el lugar",
        "Layout": "Diseño",
        "Others": "Otros",
        "Promotions": "Promociones",
        "Hotel": "Hotel",
        "Banners": "Banners",
        "Colors": "Colores",
        "Themes": "Temas",
        "OrdersPrint": "Impresora de pedidos",
        "AdminSettings": "Administración",
        current_warehouse_system: "Current warehouse system",
        current_delivery_system: "Current delivery system",
        current_payment_provider: "Online payments",
    },
    "users": {
        "add": "Añadir usuario",
        "edit": "Editar usuario",
        "email": "Correo electrónico",
        "password": "Contraseña",
        "date_of_registration": "Fecha de registro",
        "registered_users": "Usuarios registrados",
        "fullname": "Nombre completo",
        "address": "Dirección",
        "phone": "Teléfono"
    },
    "dashboard": {
        "orders": "Pedidos",
        "online_customers": "Clientes en línea",
        "monthly_turnover": "Facturación mensual",
        "happy_customers": "Clientes satisfechos",
        "totalAndTodayOrders": "Total de pedidos {total}, pedidos hoy: {today}"
    },
    "statistics": {
        "users_by_date_count": "Usuarios únicos por fecha",
        "page": "Página",
        "avg_time_spent": "Tiempo promedio",
        "avg_time_spent_per_page": "Tiempo promedio en cada página",
        "total_unique_users": "Número total de usuarios únicos",
        "orders_count": "Número de pedidos",
        "total_amount": "Cantidad total",
        "avg_amount": "Promedio",
        "turnover_by_date": "Facturación por fecha",
        "orders_count_by_date": "Número de pedidos por fecha",
        "most_ordered_product_variants": "Variantes de productos más pedidos",
        "times_ordered": "Veces pedido",
        "product_name": "Producto",
        "top_visited_pages": "Páginas más visitadas y tiempo pasado en ellas"
    },
    "roles": {
        "SUPER_ADMIN": "Super Admin",
        "CLIENT_ADMIN": "Admin de todos los establecimientos",
        "SALES_ADMIN": "Gerente de ventas",
        "PLACE_ADMIN": "Admin de establecimiento",
        "WAITER": "Camarero",
        "PDF_MENU_ADMIN": "Admin de menú de imágenes",
        "RASPBERRY_ADMIN": "RPB",
        "USER": "Usuario normal"
    },
    "clients": {
        "title": "Clientes",
        "add": "Añadir cliente",
        "edit": "Editar cliente",
        "delete": {
            "title": "Eliminar cliente",
            "confirm": "¿Estás seguro de que quieres eliminar al cliente?"
        },
        "contract_start_date": "Contrato: fecha de inicio",
        "trial_start_date": "Periodo de prueba: inicio",
        "trial_end_date": "Periodo de prueba: fin",
        "monthly_fee": "Cuota mensual",
        "address": "Dirección",
        "additional_info": "Información adicional (acuerdos)",
        "owner_name": "Nombre del propietario"
    },
    "regions": {
        "title": "Regiones",
        "add": "Añadir región",
        "edit": "Editar región",
        "delete": {
            "title": "Eliminar región",
            "confirm": "¿Estás seguro de que quieres eliminar la región?"
        }
    },
    "login": {
        "title": "Inicia sesión",
        "email": "Correo electrónico",
        "password": "Contraseña",
        "login": "Entrar"
    },
    "feedback": {
        "questions": {
            "title": "Comentarios - preguntas",
            "add": "Añadir pregunta",
            "edit": "Editar pregunta",
            "question": "Pregunta",
            "question_type": "Tipo",
            "question_types": {
                "stars": "Pregunta con estrellas",
                "text": "Pregunta con respuesta escrita"
            }
        },
        "answers": {
            "title": "Comentarios - respuestas"
        }
    },
    "invoices": {
        "year": "Año",
        "month": "Mes",
        "title": "Facturas",
        "company_name": "Nombre de la empresa",
        "company_eik": "NIF",
        "company_vat_registered": "Registrada en IVA",
        "company_city": "Ciudad de la empresa",
        "company_address": "Dirección de la empresa",
        "company_person": "Responsable",
        "company_email": "Correo electrónico",
        "download": "Descargar",
        "price": "Precio",
        "price_with_words": "Precio en palabras",
        "date": "Fecha",
        "service_name": "Servicio",
        "service_price": "Precio del servicio",
        "number": "Número de factura",
        "add": "Nueva factura",
        "invoice_original": "Original",
        "invoice_copy": "Copia",
        "status": "Estado",
        "new": "Nueva",
        "sent": "Enviada",
        "paid": "Pagada",
        "canceled": "Cancelada",
        "remind_on": "Recordar el",
        "sort_by_date": "Ordenar por fecha",
        "partly_payment": "Pago parcial"
    },
    "proforms": {
        "title": "Proformas",
        "company_name": "Nombre de la empresa",
        "company_eik": "NIF",
        "company_vat_registered": "Registrada en IVA",
        "company_city": "Ciudad de la empresa",
        "company_address": "Dirección de la empresa",
        "company_person": "Responsable",
        "company_email": "Correo electrónico",
        "download": "Descargar",
        "price": "Precio",
        "price_with_words": "Precio en palabras",
        "date": "Fecha",
        "service_name": "Servicio",
        "service_price": "Precio del servicio",
        "number": "Número de Proforma",
        "add": "Nueva Proforma",
        "invoice_original": "Proforma",
        "invoice_copy": "Proforma",
        "status": "Estado",
        "new": "Nueva",
        "sent": "Enviada",
        "paid": "Pagada",
        "remind_on": "Recordar el",
        "sort_by_date": "Ordenar por fecha",
        "partly_payment": "Pago parcial"
    },
    "bookings": {
        "title": "Reservas",
        "duration": "Duración",
        "service": "Servicio",
        "statuses": {
            "unconfirmed": "No confirmada",
            "confirmed": "Confirmada",
            "canceled": "Cancelada"
        },
        "start_time": "Desde",
        "end_time": "Hasta",
        "rooms": "Habitaciones",
        "add_room": "Añadir habitación",
        "edit_room": "Editar habitación",
        "all": "Todas",
        "new": "No confirmadas",
        "confirmed": "Confirmadas",
        "calceled": "Canceladas"
    },
    "table_groups": {
        "title": "Grupos",
        "min_people_count": "Mínimo número de personas para la reserva del grupo",
        "max_people_count": "Máximo número de personas para la reserva del grupo",
        "tables": "Mesas en el grupo"
    },
    "table_regions": {
        "title": "Regiones",
        "tables": "Mesas en la región",
        "add": "Añadir región",
        "edit": "Editar región"
    },
    "restaurant_halls": {
        "title": "Salas",
        "tables": "Mesas en la sala",
        "add": "Añadir sala",
        "edit": "Editar sala"
    },
    "reservations": {
        "title": "Reservas",
        "statuses": {
            "unconfirmed": "No confirmada",
            "confirmed": "Confirmada",
            "canceled": "Cancelada"
        },
        "without_table": "Sin mesa",
        "start_time": "Desde",
        "end_time": "Hasta",
        "all": "Todas",
        "new": "No confirmadas",
        "confirmed": "Confirmadas",
        "canceled": "Canceladas",
        "date": "Fecha y hora",
        "people_count": "Número de personas",
        "restaurant_hall": "Sala",
        "tables": "Mesas",
        "people": "personas"
    },
    "ordertypes": {
        "delivery": "Entrega",
        "preorder": "Recogida en el lugar",
        "spot": "En el lugar",
        "spot_browse": "Solo para explorar",
        "tablet_mode": "Modo tableta",
        "room": "Desde la habitación"
    },
    "promocode": {
        "ignore_other_discounts": "Ignorar otros descuentos",
        "never_become_used": "Eterno (Puede ser utilizado múltiples veces - no considera si ha sido usado)",
        "never_become_used_short": "Múltiples usos"
    },
    "general_promotions": {
        "title": "Promociones",
        "edit": "Editar promoción",
        "add": "Crear promoción",
        "name": "Nombre",
        "link": "Enlace",
        "content": "Contenido",
        "active_from": "Activa desde",
        "active_to": "Activa hasta"
    },
    "registered_user_discount": {
        "title": "Descuentos para usuarios registrados",
        "description": "Al activar, los clientes registrados reciben un descuento en cada X pedido consecutivo. Ejemplo: en cada quinto pedido, el usuario recibirá un descuento de 5 € en la factura",
        "how_it_works": "¿Cómo funciona?"
    },
    "delivery_radiuses": {
        "radius_start": "Radio inicial (m.)",
        "radius_end": "Radio final (m.)",
        "min_order_price": "Precio mínimo del pedido para el radio",
        "delivery_price": "Precio de entrega para el radio",
        "required_order_price_for_free_delivery": "Precio del pedido para entrega gratuita (vacío para no aplicar)",
        "import_shape_map": "Importar mapa de coordenadas",
        "import_shape_map_info": "Introduzca coordenadas (lng, lat) separadas por una nueva línea en el siguiente formato xx.xxxxx, yy.yyyyy... El precio de entrega se determina por los radios a continuación en función de la distancia desde el establecimiento.",
        "shape_map_imported_text": "El mapa ha sido importado y se está utilizando. Si desea importar uno nuevo, ingrese las coordenadas a continuación en el campo",
        "delete_shape_map": "Eliminar mapa",
        "shape_map_not_imported_text": "No se ha importado ningún mapa"
    },
    "delivery_shapes": {
        "title": "Entregas - Mapas",
        "add": "Añadir mapa",
        "edit": "Editar mapa",
        "min_order_price": "Precio mínimo del pedido",
        "delivery_price": "Precio de entrega",
        "required_order_price_for_free_delivery": "Precio del pedido para entrega gratuita (vacío para no aplicar)",
        "coords": "Coordenadas (lat, lng)",
        "priority_info": "Si hay 2 mapas superpuestos (1 dirección está en ambos), se utilizarán los datos de aquel con menor prioridad",
        "check_if_address_in_polygon": "Introduzca una dirección para verificar si está en la región de entrega",
        "check_address": "Verificar dirección",
        "map": "Mapa - configure la región de entrega según sus necesidades"
    },
    "place_images": {
        "title": "Galería",
        "add": "Añadir imagen"
    },
    "warehouse_systems": {
        "warehouse_system": "Sistema de almacén",
        "sync_categories": "Sincronizar categorías",
        "sync_products": "Sincronizar productos",
        "sync_order_statuses": "Sincronizar estados de pedidos",
        "sync_order_delivery_channels": "Sincronizar canales de entrega de pedidos",
        "sync_order_payment_types": "Sincronizar métodos de pago de pedidos"
    },
    "delivery_systems": {
        "delivery_system": "Sistema de entregas",
        "sync_cities_and_regions": "Sincronizar ciudades y regiones para entregas",
        "resend_order": "Reenviar pedido"
    }
}