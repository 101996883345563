import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import React from 'react'

const InfoModal = (props) => (
    <>
        <Dialog
            maxWidth={'sm'}
            open={props.warehouseErrorsModalOpened}
            onClose={props.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={"mui-dialog-title-danger"}
        >
            <DialogTitle id="alert-dialog-title">
                {props.translations.orders.warehouse_errors_modal_title}
            </DialogTitle>
            <DialogContent className={"mui-dialog-content"}>
                <DialogContentText id="alert-dialog-description">
                    {props.data && props.data.message || ""}
                    <br/>

                    <div dangerouslySetInnerHTML={{ __html: props.data && props.data.data || "" }} />

                </DialogContentText>
            </DialogContent>
            <DialogActions className={"mui-dialog-actions"}>
                <Button onClick={props.onClose} color="delete" className="">
                    {props.translations.common.close}
                </Button>
                {/* <Button onClick={() => { props.deleteOrder(); props.onClose() }} className={"mui-color-danger"} autoFocus>
                    {props.translations.common.delete}
                </Button> */}
            </DialogActions>
        </Dialog>
    </>
)

export default InfoModal;