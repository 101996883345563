import React, { useRef } from 'react'
import { NavLink } from 'react-router-dom';
import { ADMIN_URL } from '../../constants.js'
import moment from 'moment';
import Utils from '../../utils/Utils.js';
import ReactToPrint from 'react-to-print';
import ComponentToPrint from './ComponentToPrint';
import PAYMENT_STATUSES from './../common/enums/paymentStatuses';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import ComponentToPrintGrouppedByCategories from './ComponentToPrintGrouppedByCategories.js';

const ListEachSpotOrderBox = (props) => {
    const componentRef = useRef();
    const getBoxBackground = (order, orderStatus = null, key = 1) => {
        let status, bill_wanted;
        if (orderStatus) {
            status = orderStatus;
        } else {
            status = order.status;
            bill_wanted = order.bill_wanted;
        }

        if (status == "rejected") {
            return 'repeating-linear-gradient(  45deg,  #ddd,  #cfcfcf 50px,  #ddd 50px,  #cfcfcf 50px)';
        }

        if (status == 'cooked_spot' || status == 'cooked_delivery' || status == 'cooked_preorder' || status == 'cooked_room') {
            return '#fff4cc';
        }

        if (status == 'served_spot' || status == 'on_the_way_delivery') {
            return '#ccfffd';
        }

        if (status == 'paid') {
            return '#cbffdd'
        }

        if (bill_wanted) {
            return '#ff6a6a';
        }

        return 'white';
        // return key % 2 != 0 ? 'white' : '#f2f2f2';

    }

    const large_print_button = window.localStorage.getItem('large_print_button');

    let orderStatusButtons = Object.keys(props.statuses).map((s, ind) => {
        if (localStorage.getItem('tabl_raspberry') == 1 && Utils.getSettingsValue(props.settings, 'raspberry_display') == '1' && s == 'ordered') return null;

        return <button key={ind}
            style={{
                width: '100%',
                background: getBoxBackground(null, s, props.index),
                border: '1px solid #ccc',
                borderLeft: ind == 0 ? '0' : '1px solid #ccc',
                borderRight: ind == Object.keys(props.statuses).length - 1 ? '0' : '1px solid #ccc',
                padding: (localStorage.getItem('tabl_raspberry') == 1 && Utils.getSettingsValue(props.settings, 'raspberry_display') == '1' ? '15px 2px' : '10px 2px'),
                'borderCollapse': 'collapse',
                fontWeight: props.order.status == s ? 'bold' : 'normal'
            }}
            onClick={() => props.updateStatus(props.order.id, s, props.order)}
        >
            {props.statuses[s]}
        </button>
    })
    // props.statuses

    let orderStatusOptions = Object.keys(props.statuses).map((s, ind) => {
        return <option key={ind} value={s}>{props.statuses[s]}</option>
    })


    let countAdditionalPriceCalculated = 0;
    //Calculate total sum
    for (let i = 0; i < Object.keys(props.order.order_productvariants).length; i++) {

        //Add additional price calculations - Only for pickup and delivery
        if ((props.order.ordertypeId == 2 || props.order.ordertypeId == 3) && (props.settings && props.settings.show_variant_additional_price && props.settings.show_variant_additional_price.value && props.settings.show_variant_additional_price.value == 1) && (props.order.order_productvariants[i] && props.order.order_productvariants[i].product_variant && ((parseFloat(props.order.order_productvariants[i].product_variant.additional_price)).toFixed(2)) != '0.00')) {
            countAdditionalPriceCalculated += 1 * props.order.order_productvariants[i].quantity;
        }

    }


    let orderDateDay = moment(props.order.createdAt).format('DD.MM');
    let orderDateHour = moment(props.order.createdAt).format('HH:mm');
    let updateDate = moment(props.order.updatedAt).format('DD.MM / HH:mm');
    let updateTime = moment(props.order.updatedAt).format('HH:mm');

    let productRequiredIngredients = [];
    let productAddablengredients = [];
    let productRemovableIngredients = [];

    let products = Object.values(props.order.order_productvariants).map((opv, ind) => {
        const prodComment = props.order.order_comment ? JSON.parse(props.order.order_comment).find(p => p.variantId === opv.productVariantId) : undefined;
        let checkboxChecked = false
        let newStatus;

        productRequiredIngredients = [];
        productAddablengredients = [];
        productRemovableIngredients = [];

        // if (pr.order_productvariant.status == 'cooking') {
        //     newStatus = 'delivered'
        // }
        // if (pr.order_productvariant.status == 'cooking') {
        if (props.order.status == 'ordered') {
            checkboxChecked = opv.status == 'done'
            if (checkboxChecked) {
                newStatus = 'cooking'
            } else {
                newStatus = 'done'
            }
        } else if (props.order.status == 'cooked_spot') {
            checkboxChecked = opv.status == 'delivered';
            if (checkboxChecked) {
                newStatus = 'done'
            } else {
                newStatus = 'delivered'
            }
        } else if (props.order.status == 'served_spot') {

        }

        //     if (props.order.status == 'cooked_spot') {
        //         newStatus = 'delivered'
        //     }
        // }
        // if (pr.order_productvariant.status == 'done') {
        //     newStatus = 'delivered'
        // }

        const onChangeProductVariantCheckbox = () => {
            if (Utils.getSettingsValue(props.settings, 'raspberry_display') == '1' && localStorage.getItem('tabl_raspberry') == '1') {
                Utils.navigateTo('/admin/products/edit/' + opv.product_variant.productId)
            } else if (props.order.status == 'ordered' || props.order.status == 'cooked_spot') {
                props.updateVariantStatus(opv.id, newStatus)
            }
        }

        let product;
        if (props.all_products && opv && opv.product_variant) {
            product = props.all_products[opv.product_variant.productId];
        }
        return <React.Fragment key={ind}>
            {(props.order.status == 'ordered' || props.order.status == 'cooked_spot') ? <input type="checkbox" onChange={onChangeProductVariantCheckbox} checked={checkboxChecked} className="d-inline medium-checkbox valign-middle c-pointer mleft10" style={{ width: 'auto !important', position: 'absolute', marginTop: 8 }} /> : null}
            <p key={ind} onClick={onChangeProductVariantCheckbox} className={" fsize-17 p-10 c-pointer " + (checkboxChecked ? " tdecoration-linethrough" : "")} style={{
                display: 'inline-block !important',
                padding: 0,
                paddingRight: 5,
                'padding-left': (props.order.status == 'ordered' || props.order.status == 'cooked_spot') ? 36 : 5,
                margin: 0,
            }}>
                {opv.quantity} x <b>{opv.product_variant && opv.product_variant.product && opv.product_variant.product.name || ''}</b> - <i>{opv.product_variant && opv.product_variant.size || ''}</i> /{Utils.calculateProductPriceOrder(opv, props.settings, opv.opv_ingredients, 1, (props.order.ordertypeId == '2' || props.order.ordertypeId == '3'))}{/*opv.product_variant && opv.product_variant.price || ''*/} {(props.settings.default_currency_suffix && props.settings.default_currency_suffix.value ? props.settings.default_currency_suffix.value : 'лв.')}/
                -&nbsp;
                <span style={{ 'text-decoration': 'none', background: '#e8e8e8', 'border-radius': 4, padding: '1px 5px' }} >
                    ({opv.cat_hierarchy && opv.cat_hierarchy.name || ''})
                </span>
                {((props.order.ordertypeId == 2 || props.order.ordertypeId == 3) && ((props.settings && props.settings.show_variant_additional_price && props.settings.show_variant_additional_price.value && props.settings.show_variant_additional_price.value == 1) && opv.product_variant && ((parseFloat(opv.product_variant.additional_price)).toFixed(2) != '0.00')) ? (' + ' + (props.translations && props.translations.common && props.translations.common.box ? props.translations.common.box : '') + ' ' + opv.product_variant.additional_price + ' ' + (props.settings.default_currency_suffix && props.settings.default_currency_suffix.value ? props.settings.default_currency_suffix.value : 'лв.')) : '')}

                {prodComment && prodComment.comment ? <> - <span className="badge badge-secondary" style={{ fontSize: 13 }}>{prodComment.comment}</span></> : null}

                {opv.opv_ingredients.map((ing, k) => {
                    if (ing.ingredient_type == "required") {
                        if (ing.ingredient) {
                            productRequiredIngredients.push(ing.ingredient.name);
                        }
                    }
                    if (ing.ingredient_type == "addable") {
                        if (ing.ingredient) {
                            productAddablengredients.push(ing.ingredient.name);
                        }
                    }
                    if (ing.ingredient_type == "removable") {
                        if (ing.ingredient) {
                            productRemovableIngredients.push(ing.ingredient.name);
                        }
                    }
                })}

                {
                    productRequiredIngredients.length > 0 ?
                        <div style={{ fontStyle: 'italic' }}>
                            {productRequiredIngredients.join(", ")}
                        </div>
                        :
                        null
                }
                {
                    productAddablengredients.length > 0 ?
                        <div style={{ color: '#008017', fontStyle: 'italic' }}>+
                            {productAddablengredients.join(", ")}
                        </div>
                        :
                        null
                }
                {
                    productRemovableIngredients.length > 0 ?
                        <div style={{ color: '#c50000', fontStyle: 'italic' }}>-
                            {productRemovableIngredients.join(", ")}
                        </div>
                        :
                        null
                }
                <br />
            </p>

        </React.Fragment>
    })

    let paymentMethod = 'cash';
    if (props.order.table && props.order.table.id) {
        props.allBullWantedItems.filter(allbw => allbw.tableId == props.order.table.id).map((b, count) => {
            // updateDate = moment(b.updatedAt).format('DD.MM.YYYY HH:mm');

            // orderDateDay = moment(b.updatedAt).format('DD.MM');
            // orderDateHour = moment(b.updatedAt).format('HH:mm');

            if (b.status != 'paid') {
                if (b.payment_method != null) {
                    paymentMethod = b.payment_method;
                }
            }
            // Object.values(b.ProductVariants).map((pv, count) => {
            //     totaPriceTable += parseFloat(pv.price) * 1 * parseInt(pv.order_productvariant.quantity);
            // });
        });
    }

    // let currentRegion = null;
    // if (props.order.regionId && props.regions) {
    //     currentRegion = props.regions.find(r => r.id == props.order.regionId)
    //     if (currentRegion && currentRegion.name) {
    //         currentRegion = currentRegion.name;
    //     }
    // }

    const payment_methods_for_order_types = Utils.getSettingsValue(props.settings, 'payment_methods_for_order_types') || {};

    return (
        <div className="col-sm-6 col-md-4 col-lg-3" style={{ padding: '0 7px' }}>
            <div className="card" style={{ borderRadius: 11 }}>
                <div className="card-body p-0 white-space-break-all-container" style={{
                    background: getBoxBackground(props.order, null, props.index), borderRadius: 11,
                }}>

                    {props.order.status == "rejected" ?
                        <div style={{
                            background: 'red',
                            color: 'white',
                            padding: 5,
                            fontSize: 11,
                            textAlign: 'center',
                            borderTopLeftRadius: 11,
                            borderTopRightRadius: 11,
                        }}>ОТКАЗАНА</div>
                        :
                        null
                    }

                    <div style={{ display: 'grid', gridTemplateColumns: '55% 45%', padding: '5px 5px 0 5px' }} >

                        <div>
                            {props.order.client_name ? <React.Fragment>{props.order.client_name} <br /></React.Fragment> : null}
                            {props.order.client_phone ? <React.Fragment><a href={"tel:" + props.order.client_phone.split(' ').join('')} >{props.order.client_phone}</a><br /></React.Fragment> : ''}

                            {props.order.isClientNew ? <span><i className={"badge badge-dark"} style={{ fontSize: 13 }}>Нов клиент</i><br /></span> : null}
                            {props.order.client_email ? <React.Fragment><a href={"mailto:" + ((props.order.client_email).split(' ').join(''))} target="_blank">{props.order.client_email}</a> <br /></React.Fragment> : null}

                            {props.order.client_address && props.order.ordertype.type == 'delivery' ? <span span className="badge badge-secondary" style={{ fontSize: 14, backgroundColor: 'rgb(232, 232, 232)' }}><a href={"https://www.google.com/maps/dir/?api=1&destination=" +
                                props.order.client_address.split("/")[0] + '' + (props.order.street_number ? ' № ' + props.order.street_number : '')
                            } target="_blank" title={"Google Карта с навигация до адреса"} style={{ color: '#23282c' }}>{props.order.client_address} /
                                {props.order.street_number ? ' № ' + props.order.street_number : null} {(props.order.ap_number ? ' Ап. ' + props.order.ap_number : '')} {(props.order.floor_number ? ' Ет. ' + props.order.floor_number : '')} {(props.order.bell_number ? ' Зв. ' + props.order.bell_number : '')}
                            </a></span> : null}

                            {props.order.ordertype && props.order.ordertype.type == "spot" && props.order.table && props.order.table.table_num ?
                                <React.Fragment>
                                    {props.translations.common.table}: <b>{props.order.table && props.order.table.table_num}</b>
                                </React.Fragment>
                                :
                                null
                            }

                            {props.order.ordertype && props.order.ordertype.type == "room" && props.order.room_number ?
                                <React.Fragment>
                                    <br />{props.translations.common.room}: <b>{props.order.room_number} {props.order.additional_order_type ? props.order.additional_order_type == 'delivery' ? '- С ДОСТАВКА' : '- ВЗИМАНЕ ОТ МЯСТО' : null}</b>
                                </React.Fragment>
                                :
                                null
                            }

                            {props.order.free_product ?
                                <React.Fragment>
                                    <br />Подарък: <b style={{ "color": "red" }}>{props.order.free_product}</b>
                                </React.Fragment>
                                :
                                null}

                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <span style={{ color: 'rgb(83, 83, 83)' }}>{props.translations.orders.order_date_short} #{props.order.place_order_number * 1}</span>
                            <br />
                            {orderDateDay} / <b>{orderDateHour}</b>

                            <p>

                                {Utils.hasFunctionalityModule("payments") ?
                                    props.order.payment_status == PAYMENT_STATUSES.PAID ?
                                        <span><b className={"badge badge-success"} style={{ fontSize: 13 }}>{'Платена с EPAY'}</b></span>
                                        :
                                        null
                                    :
                                    null
                                }

                                {payment_methods_for_order_types && (payment_methods_for_order_types[props.order.ordertype.type] && payment_methods_for_order_types[props.order.ordertype.type].length > 0) ?
                                    <>
                                        <b className={props.order.payment_method == 'cash' ? "badge badge-success" : "badge badge-info"} style={{ fontSize: 13 }}>{props.order.payment_method == 'cash' ? 'В брой' : (props.order.payment_method == 'card' ? 'С карта' : (props.order.payment_method == 'on_room_bill' ? 'На сметката на стаята' : null))}</b>
                                        &nbsp;
                                    </>
                                    :
                                    null
                                }

                                {(Utils.getSettingsValue(props.settings, 'show_cutlery_option_on_making_delivery_order') == '1' && props.order.ordertype.type == "delivery") ||
                                    (Utils.getSettingsValue(props.settings, 'show_cutlery_option_on_making_preorder_order') == '1' && props.order.ordertype.type == "preorder") ||
                                    (Utils.getSettingsValue(props.settings, 'show_cutlery_option_on_making_room_order') == '1' && props.order.ordertype.type == "room") ?
                                    <b
                                        // className={props.order.cutlery == '1' ? "badge badge-warning" : "badge badge-danger"} 
                                        className={props.order.cutlery == '1' || ((props.order.ordertype.type == "delivery" || props.order.ordertype.type == "preorder") && props.order.count_cutlery && parseInt(props.order.count_cutlery) > 0) ? "badge badge-warning" : "badge badge-danger"}
                                        style={{ fontSize: 13 }}>
                                        {props.order.cutlery == '1'
                                            ||
                                            (((props.order.ordertype.type == "delivery" || props.order.ordertype.type == "preorder")) && ((props.order.ordertype.type == "delivery" || props.order.ordertype.type == "preorder") && props.order.count_cutlery && parseInt(props.order.count_cutlery) > 0))
                                            ?
                                            (
                                                'С прибори ' + (props.order.count_cutlery > 0
                                                    ? parseFloat(props.order.count_cutlery) + ' бр. - ' + (Utils.getSettingsValue(props.settings, 'additional_price_each_cutlery') && Utils.getSettingsValue(props.settings, 'additional_price_each_cutlery') != '0' ? (parseFloat(Utils.getSettingsValue(props.settings, 'additional_price_each_cutlery')) * parseFloat(props.order.count_cutlery)).toFixed(2) + ' лв.' : '')
                                                    : '')
                                            )
                                            : (props.order.cutlery == '0' ? 'Без прибори' : null)
                                        }
                                    </b>
                                    :
                                    null
                                }

                            </p>
                            {/* {orderDate} */}
                            {/* {updateDate} */}
                        </div>

                    </div>

                    <div style={{ textAlign: 'center' }}>
                        {props.deliveryTime ?
                            <React.Fragment>
                                <br />
                                {props.translations.orders.client_delivery_time}<b>{props.order.exact_time_selected ?
                                    (Utils.getSettingsValue(props.settings, 'enable_showing_diapasone_in_times_for_delivery_or_pickup_in_order_modal') == 1 ? props.deliveryTime + ' - ' + props.addMinutesToTime(props.deliveryTime, Utils.getSettingsValue(props.settings, 'minutes_margin_working_hours_choose_date_for_pickup_or_delivery_in_order_modal')) : props.deliveryTime)
                                    // props.deliveryTime
                                    : 'възможно най-скоро'}</b>
                                {props.order.exact_time_selected && !Utils.checkDatesInSameDayAndHour(props.order.delivery_time, props.order.createdAt) ?
                                    <><br />
                                        <span className="badge badge-warning fsize-13"><i>БЪДЕЩА ПОРЪЧКА</i></span>
                                    </>
                                    :
                                    null
                                }

                            </React.Fragment>
                            :
                            null
                        }

                        {props.pickupTime ?
                            <React.Fragment>
                                <br />
                                {props.translations.orders.client_pickup_time}<b>{props.order.exact_time_selected ?
                                    (Utils.getSettingsValue(props.settings, 'enable_showing_diapasone_in_times_for_delivery_or_pickup_in_order_modal') == 1 ? props.pickupTime + ' - ' + props.addMinutesToTime(props.pickupTime, Utils.getSettingsValue(props.settings, 'minutes_margin_working_hours_choose_date_for_pickup_or_delivery_in_order_modal')) : props.pickupTime)
                                    // props.pickupTime
                                    : 'възможно най-скоро'}</b>
                                {props.order.exact_time_selected && !Utils.checkDatesInSameDayAndHour(props.order.pickup_time, props.order.createdAt) ?
                                    <><br />
                                        <span className="badge badge-warning fsize-13"><i>БЪДЕЩА ПОРЪЧКА</i></span>
                                    </>
                                    :
                                    null
                                }
                            </React.Fragment>
                            :
                            null
                        }
                    </div>


                    <br />

                    {(props.order.bill_wanted && props.order.status != 'paid') || (props.order.table && props.order.table.table_num && Object.values(props.billWantedTableIds).includes(parseInt(props.order.table.table_num))) ?
                        <React.Fragment>
                            <div className="text-value" style={{ textAlign: 'center', color: 'black' }}>{props.translations.common.bill}: {props.translations.common[paymentMethod]}</div>
                            <br />
                        </React.Fragment>
                        :
                        null
                    }

                    {/* 
                    <select className="form-control" defaultValue={props.order.status} onChange={(ev) => props.updateStatus(props.order.id, ev.target.value, props.order)} style={{ borderRadius: 0, background: getBoxBackground(props.order) }}>
                        {orderStatusOptions}
                    </select> 
                    */}

                    <div style={{ display: 'flex', marginBottom: 10 }}>
                        {orderStatusButtons}
                    </div>

                    {props.order.order_text_comment && props.order.order_text_comment.length > 0 ?
                        <>
                            <span style={{ fontWeight: 'bold', textAlign: 'center', display: 'block', marginTop: 25 }}>Коментар към поръчката:</span>
                            <span style={{
                                display: 'block',
                                background: '#ccc',
                                padding: 6,
                            }}>
                                {props.order.order_text_comment}
                            </span>
                        </>
                        :
                        null
                    }
                    <br /><br />

                    {products}

                    <div style={{ textAlign: 'center' }}>
                        <p style={{ fontSize: 15, border: '1px solid #9c9c9c', padding: 2, 'border-radius': 5, width: 'fit-content', margin: '0 auto' }}><b>{props.order.total_price ? 'Общо: ' + props.order.total_price : null}&nbsp;{props.translations.common.currency}</b></p>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        {props.order.discount_price && props.order.discount_price != 0 ?
                            <div style={{ textDecoration: props.order.promocode && props.order.promocode.ignore_other_discounts ? 'line-through' : 'none' }}>Отстъпка: {props.order.discount_price} {props.translations.common.currency} {props.order.discount_type == 'percent' && props.order.discount_value ? <span>({props.order.discount_value} %)</span> : null}</div>
                            :
                            null
                        }
                        {props.order.promocode && props.order.promocode.discount_value && props.order.promocode.discount_type ?
                            <div>Отстъпка Промокод: {props.order.promocode.discount_value} {props.order.promocode.discount_type == 'percent' ? '%' : props.translations.common.currency}</div>
                            :
                            null
                        }

                        {props.order.user_discount_value && props.order.user_discount_type && props.order.user_discount_price ?
                            <div title={"Отстъпка регистриран потребител"}>
                                Лична отстъпка: {props.order.user_discount_value} {props.order.user_discount_type == 'percent' ? '%' : props.settings.default_currency_suffix && props.settings.default_currency_suffix.value || props.translations.common.currency} <span>({props.order.user_discount_price} {props.settings.default_currency_suffix && props.settings.default_currency_suffix.value || props.translations.common.currency})</span>
                            </div>
                            :
                            null
                        }

                        {props.order.delivery_price && props.order.delivery_price != 0 ?
                            <div>Доставка: {props.order.delivery_price} {props.translations.common.currency}</div>
                            :
                            null
                        }

                        {countAdditionalPriceCalculated > 0 ?
                            <React.Fragment>
                                /{(props.translations && props.translations.common && props.translations.common.included ? props.translations.common.included : '')} {countAdditionalPriceCalculated} {(props.translations && props.translations.common && props.translations.common.box ? props.translations.common.box.toLowerCase() : '')}/
                            </React.Fragment>
                            :
                            ''
                        }
                    </div>

                    {Utils.getSettingsValue(props.settings, 'times_until_order_ready') ?
                        <>
                            <FormControl fullWidth>
                                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                    {props.translations.common.time_until_order_ready}
                                </InputLabel>
                                <NativeSelect
                                    defaultValue={props.order.time_until_order_ready}
                                    inputProps={{
                                        name: '',
                                        id: 'uncontrolled-native',
                                    }}
                                    onChange={(e) => props.changeTimeUntilOrderReady(e, props.order.id)}
                                >
                                    <option>-</option>
                                    {Object.values(Utils.getSettingsValue(props.settings, 'times_until_order_ready')).map(time => {
                                        return <option value={time}>{time}мин.</option>
                                    })}
                                </NativeSelect>
                                <br />
                            </FormControl>

                        </>
                        :
                        null
                    }

                    {props.order.anonymous_user ?
                        props.order.anonymous_user.banned == true ?
                            <button className='btn btn-danger' onClick={() => props.banUnbanAnonUser(props.order.anonymous_user.id, 0)} style={{ paddingTop: 0, paddingBottom: 0 }}>Device {props.order.anonymousUserId} - banned</button>
                            :
                            <button className='btn btn-info' onClick={() => props.banUnbanAnonUser(props.order.anonymous_user.id, 1)} style={{ paddingTop: 0, paddingBottom: 0 }}>Device {props.order.anonymousUserId} - not banned</button>
                        :
                        null
                    }

                    <div className="progress progress-xs mt-2">
                        <div className="progress-bar bg-success" role="progressbar"
                            // style={{ width: '25%' }}
                            aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>

                    {/* {props.order.status == 'served_spot' || (props.order.bill_wanted && props.order.status != 'paid') ?
                        <button type="submit" className="btn btn-sm btn-success" onClick={() => { props.updateStatus(props.order.id, "paid", props.order) }} style={{ display: 'block', margin: '0 auto' }}>
                            <i className="fa fa-dot-circle-o"></i> {props.translations.common.paid}
                        </button>
                        :
                        null
                    } */}

                    <div style={{
                        textAlign: 'center',
                        'border-bottom-left-radius': 11,
                        'border-bottom-right-radius': 11,
                    }} className={props.order.seen_order == 0 && props.order.status == 'ordered' &&
                        (
                            Utils.getSettingsValue(props.settings, 'raspberry_display') == '1'
                                ? true
                                : localStorage.getItem('tabl_raspberry') == '1'
                                    ? false
                                    : true
                        )
                        ? (props.index % 2 != 0 ? "unseen_order_odd" : "unseen_order_even") : ""}>


                        {Utils.getSettingsValue(props.settings, 'allow_auto_print_orders') == 1 ?
                            <>
                                <ReactToPrint
                                    trigger={() =>
                                        <button className="btn btn-pill btn-link" type="button" >
                                            <i className={"fas fa-print " + (large_print_button != null ? 'large-print-button' : '')}></i>
                                        </button>
                                    }
                                    onBeforePrint={() => {
                                        props.updatePrinted(props.order.id);
                                        // console.log('(C) printing orderId:', props.order.id); // pls do not remove this :)
                                    }}
                                    content={() => componentRef.current}
                                />
                                <div style={{ display: 'none' }}>
                                    <ComponentToPrint
                                        ref={componentRef}
                                        settings={props.settings}
                                        order={props.order}
                                        all_products={props.all_products}
                                        regions={props.regions}
                                        ingredients={props.ingredients}
                                        addMinutesToTime={props.addMinutesToTime}
                                    />
                                </div>
                            </>
                            :
                            null
                        }

                        {Utils.getSettingsValue(props.settings, 'show_custom_print_orders_button_group_by_categories') == 1 ?
                            <>
                                <ReactToPrint
                                    trigger={() =>
                                        <button className="btn btn-pill btn-link" type="button" >
                                            <i className={"fas fa-print " + (large_print_button != null ? 'large-print-button' : '')} style={{ color: '#4dbd74' }}></i>
                                        </button>
                                    }
                                    onBeforePrint={() => {
                                        props.updatePrinted(props.order.id);
                                        // console.log('(C) printing orderId:', props.order.id); // pls do not remove this :)
                                    }}
                                    content={() => componentRef.current}
                                />
                                <div style={{ display: 'none' }}>
                                    <ComponentToPrintGrouppedByCategories
                                        ref={componentRef}
                                        settings={props.settings}
                                        order={props.order}
                                        all_products={props.all_products}
                                        regions={props.regions}
                                        ingredients={props.ingredients}
                                        prodPrintGroups={props.prodPrintGroups}
                                        addMinutesToTime={props.addMinutesToTime}
                                    />
                                </div>
                            </>
                            :
                            null
                        }

                        {props.order.printed == 1 ?
                            <span className="badge badge-secondary">Отпечатана</span>
                            :
                            null
                        }

                        <button className="btn btn-pill btn-link" type="button" onClick={() => props.openDeleteOrderModal(props.order.id, props.order.place_order_number)}>
                            <i className="fas fa-trash"></i>
                        </button>

                        <button className="btn btn-pill btn-link" type="button"
                            onClick={() => props.openRejectOrderModal(props.order)}
                            style={{ color: props.order.status == "rejected" ? "red" : "#20a8d8" }}>
                            <i className="fas fa-power-off" data-toggle="tooltip" data-placement="top" title={props.translations && props.translations.orders.statuses.rejected}></i>
                        </button>

                        <small className="text-muted" style={{ fontSize: 13, color: 'black !important' }}>{props.translations.orders.update_date} {updateTime}</small>
                    </div>
                </div>
            </div>
        </div >

    );

}

export default ListEachSpotOrderBox;