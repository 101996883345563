import React, { Component } from 'react'
import { connect } from 'react-redux';
import { editReservation, listReservations, clearMessages } from '../actions/reservations.actions';
import { listPlaces } from './../../places/actions/places.actions';
import { listRooms } from './../../rooms/actions/rooms.actions';
import { listReservationTables } from './../../tables/actions/tables.actions';
import ReservationForm from './ReservationForm';
import { NavLink } from 'react-router-dom';
import { ADMIN_URL } from '../../../constants';
import { listTables } from '../../../actions/tablesActions';
import Auth from '../../../utils/Auth';
import Utils from '../../../utils/Utils';

class EditReservationPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            reservation: {
                id: this.props.match.params.id,
                date_time_from: this.props.reservation.start_time,
                place_id: this.props.reservation.place_id,
                room_id: this.props.reservation.room_id,
                people_count: this.props.reservation.people_count,
                client_name: this.props.reservation.client_name,
                client_phone: this.props.reservation.client_phone,
                description: this.props.reservation.description,
                menu_included: this.props.reservation.menu_included,
                paid_ammount: this.props.reservation.paid_ammount,
                status: this.props.reservation.status,
            },
            rooms: [],
            tables: [],
            tableIds: [],
            tablesCount: 0,
            errors: [],
            successMsg: ""
        }

        this.onChange = this.onChange.bind(this);
        this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setRoom(nextProps);
        // console.log('nextProps.reservation', nextProps.reservation);
        if (nextProps.reservation && Object.values(nextProps.reservation).length > 0 && nextProps.reservation.tables) {
            let tablesCount = this.state.tablesCount;
            // console.log('nextProps.reservation.tables', nextProps.reservation.tables);
            let tables = nextProps.reservation.tables.map(t => ({
                key: tablesCount++,
                value: t.id
            }))

            this.setState({
                reservation: nextProps.reservation, tables: tables, tablesCount: tablesCount
            })
        }
    }

    componentWillMount() {
        this.props.listTables();
        // this.props.listReservationTables();        
        this.props.listReservations();
        this.props.clearMessages();
    }

    componentDidMount() {
        this.setRoom();
        // listReservationTables();
    }

    setRoom = (nextProps) => {
        if (typeof nextProps == "undefined") {
            nextProps = this.props;
        }

        let places = nextProps.allPlaces;
        let placeId, roomId;
        if (nextProps.reservation.place_id) {
            placeId = nextProps.reservation.place_id
        } else {
            placeId = places[Object.keys(places)[0]] ? places[Object.keys(places)[0]]['id'] : 0;
        }
        if (placeId != 0) {
            let rooms = Object.values(nextProps.allRooms).filter(room => room.place_id == placeId);

            if (nextProps.reservation.room_id) {
                roomId = nextProps.reservation.room_id
            } else {
                roomId = rooms.length > 0 ? rooms[0].id : 0
            }

            let res = this.state.reservation;
            res.place_id = placeId;
            res.room_id = roomId;

            this.setState({ reservation: res, rooms: rooms });
        }
    }

    // onChange(e) {
    //     this.setState({ [e.target.name]: e.target.value });
    // }


    onChange = (e) => {
        let stateObject = Utils.onChange(e);

        let resState = this.state.reservation;
        resState[e.target.name] = stateObject[e.target.name];

        this.setState({ reservation: resState });
    }



    // onReservationChange = e => {
    //     let res = this.state.reservation;
    //     res[e.target.name] = e.target.value;
    //     this.setState({ reservation: res });
    // }

    onReservationChange = e => {
        let res = this.state.reservation;
        res[e.target.name] = e.target.value;
        // console.log('set this new change', res);
        this.setState({ reservation: res });
    }

    onChangeCheckbox(e) {
        // this.setState({ [e.target.name]: e.target.checked });
        let resState = this.state.reservation;
        resState[e.target.name] = e.target.checked;
// console.log('resState',resState);
        this.setState({ reservation: resState });

    }

    onPlaceChange = (e) => {
        let placeId = e.target.options[e.target.selectedIndex].value;
        let roomsArr = Object.values(this.props.allRooms);
        let rooms = roomsArr.filter(room => room.place_id == placeId);
        let res = this.state.reservation;
        res.place_id = placeId;
        res.room_id = rooms[0].id;
        this.setState({ reservation: res, rooms: rooms });
    }

    onTableSelectChange = (e) => {
        const key = parseInt(e.target.name);
        let foundIndex = parseInt(this.state.tables.findIndex(i => parseInt(i.key) === key));
        // console.log(this.state.tables, typeof(key), foundIndex);return;

        let tableId = e.target.options[e.target.selectedIndex].value;
        let newTable = { key: key, value: tableId }
        let tables = this.state.tables;
        tables[foundIndex] = newTable;

        this.setState({ tables: tables })
    }

    addTable = () => {
        let tablesCount = this.state.tablesCount;
        let newTable = { key: tablesCount, value: '' };
        // console.log('newTable',newTable);
        tablesCount++;
        const tables = [...this.state.tables, newTable];
        this.setState({ tables: tables, tablesCount: tablesCount })
    }

    removeTable = (key) => {
        const filteredTables = this.state.tables.filter(t => {
            return t.key !== key
        })
        this.setState({ tables: filteredTables })
    }

    validate = () => {
        let errors = [];
        // console.log('this.state.reservation', this.state.reservation);
        if (this.state.reservation.date_time_from.length === 0) {
            errors.push("Невалидна дата на резервацията");
        }

        if (this.state.reservation.people_count <= 0) {
            errors.push("Невалиден брой хора. Трябва да е повече от 0");
        }

        if (this.state.reservation.client_name.length === 0) {
            errors.push("Невалидно име");
        }

        if (this.state.reservation.client_phone.length === 0) {
            errors.push("Невалиден телефон");
        }

        this.state.tables.map(t => {
            if (!Number.isInteger(parseInt(t.value))) {
                errors.push("Избрана е невалидна маса.");
                console.log('erroring');
            }
        })

        return errors;
    }

    onSubmit(e) {
        e.preventDefault();

        const tableIds = this.state.tables.map(t => t.value)

        const errors = this.validate();
        if (errors.length > 0) {
            this.setState({ errors: errors });
            return;
        } else {
            this.setState({ errors: [] });
        }


        const post = //this.state.reservation; 
        {
            // id: this.state.reservation.id,
            // date_time_from: this.state.reservation.date_time_from,
            // place_id: this.state.reservation.place_id,
            // room_id: this.state.reservation.room_id,
            // people_count: this.state.reservation.people_count,
            // name: this.state.reservation.name,
            // phone: this.state.reservation.phone,
            // is_admin: 1,
            // 
            id: this.state.reservation.id,
            start_time: this.state.reservation.date_time_from,
            reservationDate: this.state.reservation.date_time_from,
            date: new Date(this.state.reservation.date_time_from).toISOString().split('T')[0],
            time: new Date(this.state.reservation.date_time_from).toTimeString().split(' ')[0],
            // place_id: 62,
            room_id: (Auth.getUserPlace() == 359 || Auth.getUserPlace() == 62) ? 1 : this.state.reservation.room_id,
            people_count: this.state.reservation.people_count,
            // restaurantHallId: (Auth.getUserPlace() == 359 || Auth.getUserPlace() == 62) ? 1 : '',
            place: Auth.getUserPlace(),//this.state.reservation.place_id,
            client_name: this.state.reservation.client_name,
            client_phone: this.state.reservation.client_phone,
            description: this.state.reservation.description,
            menu_included: this.state.reservation.menu_included,
            paid_ammount: this.state.reservation.paid_ammount,
            is_admin: 1,

        }

        let formData = new FormData();

        for (var key in post) {
            formData.append(key, post[key]);
        }
        formData.append('tables', JSON.stringify(tableIds))

        this.props.editReservation(formData);
    }

    render() {

        let k = 0;
        let errorFields = this.state.errors.map(e => {
            return (
                <div key={k++} className="alert alert-danger  fade show" role="alert">
                    {e}
                    <button className="close" type="button" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button>
                </div>
            )
        })

        // console.log('errorFields', errorFields);
        return (
            <main className="main">

                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><NavLink to={ADMIN_URL}>Начало</NavLink></li>
                    <li className="breadcrumb-item"><NavLink to={ADMIN_URL + "reservations"}>Резервации</NavLink></li>
                    <li className="breadcrumb-item active">Редактиране</li>
                </ol>

                <div className="container-fluid">
                    <div className="animated fadeIn">
                        <div className="row">

                            {/* <!-- /.col--> */}
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-body">

                                        {this.props.successMsg ?
                                            <div className="alert alert-success  fade show" role="alert">
                                                {this.props.successMsg}
                                            </div>
                                            : ''}

                                        {this.props.errors && this.props.errors.length > 0 ?
                                            <div className="alert alert-danger  fade show" role="alert">
                                                {this.props.errors}
                                                <button className="close" type="button" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button>
                                            </div>
                                            : ''}

                                        {this.state.errors && this.state.errors.length > 0 ? errorFields : null}


                                        <ReservationForm
                                            onSubmit={this.onSubmit}
                                            onChange={this.onChange}
                                            onChangeCheckbox={this.onChangeCheckbox}
                                            onPlaceChange={this.onPlaceChange}
                                            allPlaces={this.props.allPlaces}
                                            allRooms={this.props.allRooms}
                                            rooms={this.state.rooms}
                                            allTables={this.props.allTables}
                                            addTable={this.addTable}
                                            removeTable={this.removeTable}
                                            onTableSelectChange={this.onTableSelectChange}
                                            tables={this.state.tables}
                                            // reservation={this.state}
                                            isAdding={0}
                                            reservation={this.state.reservation}
                                            onReservationChange={this.onReservationChange}
                                        />

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </main>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let resId = ownProps.match.params.id;
    let reservation;
    // console.log('state.reservations', state.reservations);
    if (state.reservations.reservations) {
        reservation = state.reservations.reservations.filter(a => a.id == resId);
        reservation = reservation && reservation[0] ? reservation[0] : reservation;
        reservation.date_time_from = reservation.start_time
        reservation.name = reservation.client_name
        reservation.phone = reservation.client_phone
    }
    // console.log('mapped state', reservation);

    // reservation = reservation && reservation[0] ? reservation[0] : reservation;

    if (!reservation) reservation = {
        id: '',
        date_time_from: '',
        place_id: 0,
        room_id: 0,
        people_count: '',
        client_name: '',
        client_phone: '',
        status: '',
        // rooms: [],
        tables: [],
        // tableIds: [],
        // tablesCount: 0,
        // errors: []
    };
    // console.log("PREDi DA", reservation)

    return {
        reservation: reservation,
        errors: state.reservations.errors || undefined,
        successMsg: state.reservations.successMsg || undefined,
        allRooms: [],//state.rooms.rooms.records || [],
        allPlaces: [],//state.places.places.records || [],
        // allTables: [],//state.reservationTables.tables.records || [],
        reservations: state.reservations.reservations || [],
        allTables: state.tables.tables,

    }
};

export default connect(mapStateToProps, { editReservation, listReservations, listReservationTables, clearMessages, listTables })(EditReservationPage);