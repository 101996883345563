import React from 'react'
import { NavLink } from 'react-router-dom';
import { Multiselect } from 'multiselect-react-dropdown';
import DateSelect from './../common/DateSelect';
import DatePicker from "react-datepicker";

const Filter = (props) => {
    let orderStatusOptions = Object.keys(props.statuses).map((s, ind) => {
        return <option key={ind} value={s}>{props.statuses[s]}</option>
    });

    const orderPayments = ["cash", "card", "epay"]
    let orderPaymentOptions = Object.keys(orderPayments).map((s, ind) => {
        return <option key={ind} value={orderPayments[s]}>{orderPayments[s]}</option>
    });

    let orderTypeOptions = props.types.map((t, ind) => {
        return t.type == 'tablet_mode' ? null : <option key={ind} value={t.id}>{props.translations.orders.types[t.type]}</option>;
    });

    let tablesForOrders = [];
    props.tablesForOrders && Object.values(props.tablesForOrders).map(t => {
        tablesForOrders.push({ table_num: t.table_num, id: t.id })
    })

    let tableRegions = [];
    props.tableRegionsForSelectedPlaces && Object.values(props.tableRegionsForSelectedPlaces).map(t => { //for all places in orderes fil
        tableRegions.push({ name: t.name + ' - ' + props.allPlaces.find(p => p.id == t.placeId).name, id: t.id })
    })

    let places = [];
    props.allPlaces && Object.values(props.allPlaces).map(t => {
        places.push({ name: t.name, id: t.id })
    })

    let selectedPlaces = props.filterPlaces.map(placeId => {
        let currPlace = props.allPlaces && props.allPlaces.find(p => p.id == placeId);
        let name = '-';
        if (currPlace) name = currPlace.name;
        return { id: placeId, name: name }
    })

    let printGroups = [];
    let selectedPrintGroups = [];
    props.productPrintGroups && Object.values(props.productPrintGroups).map(t => {
        printGroups.push({ name: t.name, id: t.id })

        if (props.prodPrintGroups.includes(t.id)) {
            selectedPrintGroups.push({ id: t.id, name: t.name })
        }

    })

    return (
        <div className="container-fluid p-0">
            <div className="animated fadeIn">
                <div className="row">

                    {/* <DateSelect
                        onChange={props.onChange}
                        translations={props.translations}
                    /> */}

                    <div className="col-md-2">
                        <label>{props.translations.common.start_date}</label>
                        <br />
                        <DatePicker
                            className="form-control"
                            selected={props.filter.search_date_from}
                            onChange={date => props.onFieldChange('search_date_from', date)}
                        // dateFormat="d-M-Y"
                        // style={{ display: 'block' }}
                        // onCalendarClose={handleCalendarClose}
                        // onCalendarOpen={handleCalendarOpen}
                        />
                        <div className="c-pointer d-inline mleft10 valign-middle" onClick={() => props.onFieldChange('search_date_from', '')}>
                            <i className="nav-icon icon-close" style={{
                                color: '#db0000',
                                'font-size': 20
                            }}></i>
                        </div>

                    </div>

                    <div className="col-md-2">
                        <label>{props.translations.common.end_date}</label>
                        <br />
                        <DatePicker
                            className="form-control"
                            selected={props.filter.search_date_to}
                            onChange={date => props.onFieldChange('search_date_to', date)}
                        // dateFormat="d-M-Y"
                        // style={{ display: 'block' }}
                        // onCalendarClose={handleCalendarClose}
                        // onCalendarOpen={handleCalendarOpen}
                        />
                        <div className="c-pointer d-inline mleft10 valign-middle" onClick={() => props.onFieldChange('search_date_to', '')}>
                            <i className="nav-icon icon-close" style={{
                                color: '#db0000',
                                'font-size': 20
                            }}></i>
                        </div>
                    </div>

                    <div className="form-group reservation-list-search col-md-2 col-sm-6 ">
                        <div className="form-group">
                            <label htmlFor="name">{props.translations.common.order}</label>
                            <input className={`form-control`} id="search_id" type="text" placeholder=""
                                name="search_id" onChange={props.onChange} value={props.search_id} />
                        </div>
                    </div>

                    <div className="form-group reservation-list-search col-md-2 col-sm-6 " style={{ zIndex: 12 }}>
                        <div className="form-group">
                            <label htmlFor="name">{props.translations.orders.select_table_regions}</label>
                            <Multiselect
                                options={tableRegions} // Options to display in the dropdown
                                selectedValues={props.preSelectedFilterTableRegions} // Preselected value to persist in dropdown
                                onSelect={props.onTableRegionSelect} // Function will trigger on select event
                                onRemove={props.onTableRegionRemove} // Function will trigger on remove event
                                displayValue="name" // Property name to display in the dropdown options
                                placeholder={props.translations.orders.select_table_regions}
                                style={{
                                    searchBox: {
                                        background: 'white'
                                    }
                                }}
                            />
                        </div>
                    </div>

                    <div className="form-group reservation-list-search col-md-2 col-sm-6 ">
                        <div className="form-group">
                            <label htmlFor="name">{props.translations.orders.select_tables}</label>
                            <Multiselect
                                options={tablesForOrders} // Options to display in the dropdown
                                selectedValues={props.preSelectedFilterTables} // Preselected value to persist in dropdown
                                onSelect={props.onTableSelect} // Function will trigger on select event
                                onRemove={props.onTableRemove} // Function will trigger on remove event
                                displayValue="table_num" // Property name to display in the dropdown options
                                placeholder={props.translations.orders.select_tables}
                                style={{
                                    searchBox: {
                                        background: 'white'
                                    }
                                }}
                            />
                        </div>
                    </div>

                    {/* <div className="form-group reservation-list-search col-md-2 col-sm-6 ">
                        <div className="form-group">
                            <label htmlFor="name">{props.translations.common.table}</label>
                            <input className={`form-control`} id="search_table_num" type="text" placeholder=""
                                name="search_table_num" onChange={props.onChange} value={props.search_table_num} />
                        </div>
                    </div> */}

                    <div className="form-group reservation-list-search col-md-2 col-sm-6 ">
                        <div className="form-group">
                            <label htmlFor="name">{props.translations.common.status}</label>
                            <select className="form-control" name="search_status" defaultValue={props.search_status} onChange={props.onChange}>
                                <option key={0} value="">-</option>
                                {orderStatusOptions}
                            </select>
                            {/* <input className={`form-control`} id="search_person_name" type="text" placeholder="" 
                            name="search_res_name" onChange={props.onChange} value={props.search_status}/> */}
                        </div>
                    </div>

                    <div className="form-group reservation-list-search col-md-2 col-sm-6 ">
                        <div className="form-group">
                            <label htmlFor="name">{props.translations.common.payment_type}</label>
                            <select className="form-control" name="search_payment_type" defaultValue={props.filter.search_payment_type} onChange={props.onChange}>
                                <option key={0} value="">-</option>
                                {orderPaymentOptions}
                            </select>
                            {/* <input className={`form-control`} id="search_person_name" type="text" placeholder="" 
                            name="search_res_name" onChange={props.onChange} value={props.search_status}/> */}
                        </div>
                    </div>


                    <div className="form-group reservation-list-search col-md-2 col-sm-6 ">
                        <div className="form-group">
                            <label htmlFor="name">{props.translations.common.order_type}</label>
                            <select className="form-control" name="search_type" defaultValue={props.search_type} onChange={props.onChange}>
                                <option key={0} value="">-</option>
                                {orderTypeOptions}
                            </select>
                            {/* <input className={`form-control`} id="search_person_name" type="text" placeholder="" 
                            name="search_res_name" onChange={props.onChange} value={props.search_type}/> */}
                        </div>
                    </div>

                    <div className="form-group reservation-list-search col-md-2 col-sm-6 ">
                        <div className="form-group">
                            <label htmlFor="name">{props.translations.orders.client_name}</label>
                            <input className={`form-control`} id="search_client_name" type="text" placeholder=""
                                name="search_client_name" onChange={props.onChange} value={props.search_client_name} />
                        </div>
                    </div>

                    <div className="form-group reservation-list-search col-md-2 col-sm-6 ">
                        <div className="form-group">
                            <label htmlFor="name">{props.translations.orders.phone}</label>
                            <input className={`form-control`} id="search_client_phone" type="text" placeholder=""
                                name="search_client_phone" onChange={props.onChange} value={props.search_client_phone} />
                        </div>
                    </div>

                    <div className="form-group reservation-list-search col-md-2 col-sm-6 ">
                        <div className="form-group">
                            <label htmlFor="name">{props.translations.orders.client_address}</label>
                            <input className={`form-control`} id="search_client_address" type="text" placeholder=""
                                name="search_client_address" onChange={props.onChange} value={props.search_client_address} />
                        </div>
                    </div>



                    {props.allPlaces && Object.values(props.allPlaces).length > 1 ?
                        <div className="form-group reservation-list-search col-md-4 col-sm-6 " >
                            <div className="form-group">
                                <label htmlFor="name">{props.translations.orders.select_places}</label>
                                <Multiselect
                                    options={places} // Options to display in the dropdown
                                    selectedValues={selectedPlaces} // Preselected value to persist in dropdown
                                    onSelect={props.onPlaceSelect} // Function will trigger on select event
                                    onRemove={props.onPlaceRemove} // Function will trigger on remove event
                                    displayValue="name" // Property name to display in the dropdown options
                                    placeholder={props.translations.orders.select_places}
                                    style={{
                                        searchBox: {
                                            background: 'white'
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        :
                        null
                    }


                    {props.productPrintGroups && Object.values(props.productPrintGroups).length >= 1 ?
                        <div className="form-group reservation-list-search col-md-4 col-sm-6 " >
                            <div className="form-group">
                                <label htmlFor="name">{props.translations.common.choose_product_print_group}</label>
                                <Multiselect
                                    options={printGroups} // Options to display in the dropdown
                                    selectedValues={selectedPrintGroups} // Preselected value to persist in dropdown
                                    onSelect={props.onProductPrintGroupSelect} // Function will trigger on select event
                                    onRemove={props.onProductPrintGroupRemove} // Function will trigger on remove event
                                    displayValue="name" // Property name to display in the dropdown options
                                    placeholder={props.translations.common.choose_product_print_group}
                                    style={{
                                        searchBox: {
                                            background: 'white'
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        :
                        null
                    }

                    {/* <button onClick={props.makeFilter}>Filter</button> */}

                </div>
            </div>
        </div>
    )
}

export default Filter;